import ThreeColumRow from "../ThreeColumRow";
import "react-confirm-alert/src/react-confirm-alert.css";

import { useParams } from "react-router-dom";
import Loading from "../../screens/Loading";
import { usePatient } from "../../customHooks/queries/usePatient";

function PersonalInfoView({ titles, operation }) {
  const { id } = useParams();
  const { data: patient, isLoading } = usePatient(id);

  if (isLoading) return <Loading />;
  return (
    <div className="w-full h-full flex flex-col justify-between gap-1 ">
      <ThreeColumRow>
        <p className="text-xs font-light">
          <span className="text-xs block font-medium">Nombres:</span>{" "}
          {patient?.data?.persona?.nombre}{" "}
        </p>
        <p className="text-xs font-light">
          <span className="text-xs block font-medium">Apellidos:</span>{" "}
          {patient?.data?.persona?.apellidos}
        </p>
        <p className="text-xs font-light">
          <span className="text-xs block font-medium">Fecha Nacimiento:</span>{" "}
          {patient?.data?.persona?.fecha_nacimiento ? new Date(patient?.data?.persona?.fecha_nacimiento).toLocaleDateString() : ""}
        </p>
      </ThreeColumRow>
      <ThreeColumRow>
        {/* email */}
        <p className="text-xs font-light">
          <span className="text-xs block font-medium">Email:</span>{" "}
          {patient?.data?.persona?.email}
        </p>
        <p className="text-xs font-light">
          <span className="text-xs block font-medium">Celular:</span>{" "}
          {patient?.data?.persona?.telf1}
        </p>
      </ThreeColumRow>
      <ThreeColumRow>
        <p className="text-xs font-light">
          <span className="text-xs block font-medium">Numero de Seguro</span>{" "}
          {patient?.data?.num_seguro}
        </p>
        <p className="text-xs font-light">
          <span className="text-xs block font-medium">
            Empresa Aseguradora:
          </span>{" "}
          {patient?.data?.empresa_seg}
        </p>
      </ThreeColumRow>

      <ThreeColumRow>
        <p className="text-xs font-light">
          <span className="text-xs block font-medium">Domicilio:</span>{" "}
          {patient?.data?.persona?.direccion}
        </p>

        <p className="text-xs font-light">
          <span className="text-xs block font-medium">
            Lugar de residencia:
          </span>{" "}
          {patient?.data?.persona?.residencia}
        </p>
      </ThreeColumRow>
    </div>
  );
}

export default PersonalInfoView;
