import { useQuery } from "@tanstack/react-query";
import api from "./api";

const getAntecedentById = async ({ queryKey }) => {
  const [_, id] = queryKey;
  if (!id) return;
  const { data } = await api.get(`/api/v1/antecedents/${id}`);
  return data;
};

export const useAntecedent = (id) => {
  return useQuery({ queryKey: ["antecedents", id], queryFn: getAntecedentById });
};
