import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiUrl } from "../apiUrl";

// export const getAntecedentById = createAsyncThunk(
//   "antecedents/getAntecedentById",
//   async (id) => {
//     const { data } = await axios.get(`${apiUrl}/api/v1/antecedents/${id}`);
//     return data;
//   }
// );

export const updateAntecedentById = createAsyncThunk(
  "antecedents/updateAntecedentById",
  async (body) => {
    const fd = new FormData();
    if (body.files) {
      for (let i in body.files) {
        fd.append(`file_${i}`, body.files[i], body.files[i].name);
      }
    }
    for (const key in body) {
      const value = body[key];
      if (value !== undefined && value !== null) {
        fd.append(key, value);
      }
    }
    const data = await axios.post(
      `${apiUrl}/api/v1/antecedents/${body.id}`,
      fd
    );
    return data;
  }
);

export const createAntecedent = createAsyncThunk(
  "payments/createAntecedent",
  async (body) => {
    const fd = new FormData();
    if (body.files) {
      for (let i in body.files) {
        fd.append(`file_${i}`, body.files[i], body.files[i].name);
      }
    }
    for (const key in body) {
      const value = body[key];
      if (value !== undefined && value !== null) {
        fd.append(key, value);
      }
    }
    const { data } = await axios.post(`${apiUrl}/api/v1/antecedents`, fd);
    return data;
  }
);

const initialState = {
  status: "idle",
  antecedent: null,
  error: null,
};

export const antecedentSlice = createSlice({
  name: "antecedent",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(updateAntecedentById.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(updateAntecedentById.fulfilled, (state, action) => {
        state.status = "succeed";
        state.antecedent = action.payload;
      })
      .addCase(updateAntecedentById.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(createAntecedent.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(createAntecedent.fulfilled, (state, action) => {
        state.status = "succeed";
        state.antecedent = action.payload;
      })
      .addCase(createAntecedent.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default antecedentSlice.reducer;

export const getAllAntecedent = (state) => state.antecedent.antecedent;
export const getAntecedentStatus = (state) => state.antecedent.status;
export const getAntecedentError = (state) => state.antecedent.error;
