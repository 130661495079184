import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import NewPatientFast from "./Modals/NewPatientFast";

const SearchBar = ({ data, setSelected, value, placeholder, setOpen }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [newPatient, setNewPatient] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  const handleSearch = (event) => {
    const term = event.target.value;
    setSearchTerm(term);
    const filtered = data?.filter(item => (
      item.persona.nombre.toLowerCase().includes(term.toLowerCase()) || item.persona.apellidos.toLowerCase().includes(term.toLowerCase()) || item.persona.ci.toLowerCase().includes(term.toLowerCase()))
    );
    if(term.length === 0) {
      setFilteredData([]);
      setSelected(null);
    }
    else setFilteredData(filtered);
  };

  const handleBlur = () => {
    setTimeout(() => {
      if (newPatient) {
        setNewPatient(false);
      }
    }, 400);
  };

  const handleSelect = (item) => {
    setSelected(item);
    setSearchTerm(`${item?.persona?.ci?.length > 8? item?.persona?.ci?.slice(0,8): item?.persona?.ci} - ${item?.persona?.nombre} ${item?.persona?.apellidos}`);
    setFilteredData([]);
  };

  return (
    <div className="">
      <input
        type="text"
        placeholder={placeholder}
        value={searchTerm? searchTerm : value}
        onChange={handleSearch}
        onFocus={() => setNewPatient(true)}
        onBlur={handleBlur}
        className="relative w-full bg-white text-sm my-3 p-4 border rounded-lg border-border -z-1"
      />
      {
        (filteredData?.length > 0 || newPatient) &&
      
      <ul className="max-h-40 border absolute rounded-lg z-50 bg-white overflow-y-scroll">
        {filteredData?.map((item, index) => (
          <li key={item.id} onMouseDown={() => handleSelect(item)} className="border-b py-2 px-5 z-50">
            {`${item.persona.ci.length > 8? item.persona.ci.slice(0,8): item.persona.ci} - ${item.persona.nombre} ${item.persona.apellidos}`}
          </li>
        ))}
        {
          newPatient &&
        <li onMouseDown={() => {setOpen(true);}} className="bg-text font-semibold px-5 py-2 cursor-pointer"> + Nuevo Paciente . . .</li>
        }
      </ul>
      }
    </div>
  );
};

export default SearchBar;