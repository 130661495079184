import { useEffect, useState } from "react";
import { sortsDatas } from "../../components/Datas";
import {
  Button,
  Input,
  Select,
  Switchi,
  DinamicInput,
  SwitchiWithField,
} from "../../components/Form";
import { BiChevronDown } from "react-icons/bi";
import { HiOutlineCheckCircle } from "react-icons/hi";
import { toast } from "react-hot-toast";
import TwoColumRow from "../../components/TwoColumRow";
import ThreeColumRow from "../../components/ThreeColumRow";
import { FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  createAntecedent,
  updateAntecedentById,
} from "../../redux/features/antecedentsSlice";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import ConfirmationAlert from "../../components/ConfirmationAlert";
import MultipleUploader from "../../components/MultipleUploader";
import Loading from "../Loading";
import { FileIcon } from "../../components/FileIcon";
import { useAntecedent } from "../../customHooks/queries/useAntecedent";

function HealthInfomation() {
  const [archivos, setArchivos] = useState(null);
  const [files, setFiles] = useState([]);
  const [bloodType, setBloodType] = useState(sortsDatas.bloodTypeFilter[0]);
  const [hta, setHta] = useState(false);
  const [dm, setDm] = useState(false);
  const [icc, setIcc] = useState(false);
  const [coronaria, setCoronaria] = useState(false);
  const [vascular, setVascular] = useState(false);
  const [pulmonar, setPulmonar] = useState(false);
  const [arritmia, setArritmia] = useState(false);
  const [dislipidemia, setDislipidemia] = useState(false);
  const [fuma, setFuma] = useState(false);
  const [vape, setVape] = useState(false);
  const [alcohol, setAlcohol] = useState(false);
  const [enfermedades, setEnfermedades] = useState(false);
  const [enfermedadesDescription, setEnfermedadesDes] = useState("");
  const [otros, setOtros] = useState(false);
  const [otrosDescription, setOtrosDes] = useState("");
  const [alergias, setAlergias] = useState(false);
  const [alergiasDescription, setAlergiasDes] = useState("");
  const [medUso, setMedUso] = useState(false);
  const [medUsoDescription, setMedUsoDes] = useState("");
  const [otrosMed, setOtrosMed] = useState(false);
  const [otrosMedDescription, setOtrosMedDes] = useState("");
  const [quirurgicos, setQuirurgicos] = useState(false);
  const [quirurgicosDescription, setQuirurgicosDes] = useState("");

  const navigate = useNavigate();

  const { id } = useParams();
  const dispatch = useDispatch();


  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const {data: antecedent, isLoading, error} = useAntecedent(id);
  useEffect(() => {
    if (antecedent?.id) {
      setHta(antecedent.hta);
      setDm(antecedent.dm);
      setIcc(antecedent.icc);
      setCoronaria(antecedent.coronaria);
      setVascular(antecedent.vascular);
      setPulmonar(antecedent.pulmonar);
      setArritmia(antecedent.arritmia);
      setDislipidemia(antecedent.dislipidemia);
      setFuma(antecedent.fuma);
      setVape(antecedent.vape);
      setAlcohol(antecedent.alcohol);
      setEnfermedades(antecedent.enfermedades);
      setEnfermedadesDes(antecedent.enfermedades_description);
      setOtros(antecedent.otros);
      setOtrosDes(antecedent.otros_description);
      setAlergias(antecedent.alergias);
      setAlergiasDes(antecedent.alergias_description);
      setMedUso(antecedent.medicacion);
      setMedUsoDes(antecedent.medicacion_description);
      setOtrosMed(antecedent.otros_med);
      setOtrosMedDes(antecedent.otros_med_description);
      setQuirurgicos(antecedent.quirurgicos);
      setQuirurgicosDes(antecedent.quirurgicos_description);
      setArchivos(antecedent.archivos);
      setValue("altura", antecedent.altura);
      setValue("peso", antecedent.peso);
      setValue("text_hta", antecedent.text_hta);
      setValue("text_dm", antecedent.text_dm);
      setValue("text_icc", antecedent.text_icc);
      setValue("text_coronaria", antecedent.text_coronaria);
      setValue("text_vascular", antecedent.text_vascular);
      setValue("text_pulmonar", antecedent.text_pulmonar);
      setValue("text_arritmia", antecedent.text_arritmia);
      setValue("text_dislipidemia", antecedent.text_dislipidemia);
      setValue("text_fuma", antecedent.text_fuma);
      setValue("text_vape", antecedent.text_vape);
      setValue("text_alcohol", antecedent.text_alcohol);
      setBloodType(
        sortsDatas.bloodTypeFilter.find(
          (element) => element.name === antecedent.sangre
        )
      );
    }
  }, [id, antecedent?.id]);
  const onSubmit = async (body) => {
    ConfirmationAlert({
      title: antecedent?.id
        ? "Actualizar Antecedentes"
        : "Registrar Antecedentes",
      text: `Estas seguro de ${
        antecedent?.id ? "actualizar" : "registar"
      } los antecedentes del paciente?`,
      onAccept: async () => {
        body.hta = hta;
        body.dm = dm;
        body.icc = icc;
        body.coronaria = coronaria;
        body.vascular = vascular;
        body.pulmonar = pulmonar;
        body.arritmia = arritmia;
        body.dislipidemia = dislipidemia;
        body.fuma = fuma;
        body.vape = vape;
        body.alcohol = alcohol;
        body.enfermedades = enfermedades;
        body.enfermedades_description = enfermedadesDescription;
        body.otros = otros;
        body.otros_description = otrosDescription;
        body.alergias = alergias;
        body.alergias_description = alergiasDescription;
        body.medicacion = medUso;
        body.medicacion_description = medUsoDescription;
        body.otros_med = otrosMed;
        body.otros_med_description = otrosMedDescription;
        body.quirurgicos = quirurgicos;
        body.quirurgicos_description = quirurgicosDescription;
        body.files = files;
        body.patient_id = id;
        body.sangre = bloodType.name;
        body.archivos = archivos;
        if (!antecedent?.id) {
          const newData = await dispatch(createAntecedent(body));
          if (newData?.error) {
            toast.error(`No se pudo crear el antecedente: ${error}`);
          } else {
            setFiles([]);
            toast.success("Antecedente creado con exito!");
            navigate(`/patients/preview/${id}`);
          }
        } else {
          body.id = antecedent.id;
          const newData = await dispatch(updateAntecedentById(body));

          if (newData?.error) {
            toast.error(`No se pudo actualizar el antecedente: ${error}`);
          } else {
            setFiles([]);
            toast.success("Antecedente actualizado con exito!");
            navigate(`/patients/preview/${id}`);
            window.location.reload();
          }
        }
      },
    });
  };

  const deleteFile = (index) => {
    setFiles(files.filter((file, i) => index !== i));
  };

  return (
    <div className="flex-colo gap-4">
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex gap-3 flex-col w-full col-span-6">
          <ThreeColumRow>
            {/* select  */}
            <div className="flex w-full flex-col gap-3">
              <p className="text-black text-sm">Grupo Sanguineo</p>
              <Select
                selectedPerson={bloodType}
                setSelectedPerson={setBloodType}
                datas={sortsDatas.bloodTypeFilter}
              >
                <div className="w-full flex-btn text-textGray text-sm p-4 border border-border font-light rounded-lg focus:border focus:border-subMain">
                  {bloodType?.name} <BiChevronDown className="text-xl" />
                </div>
              </Select>
            </div>
            {/* weight */}
            <Input
              label="Peso"
              color={true}
              type="number"
              placeholder={"60kg"}
              register={register("peso", {
                min: {
                  value: 0.1,
                  message: "El peso es en kg y debe ser mayor a 0.",
                },
              })}
              error={errors.peso}
            />
            {/* height */}
            <Input
              label="Altura"
              color={true}
              type="text"
              placeholder={"170cm"}
              register={register("altura", {
                min: {
                  value: 20,
                  message: "La altura es en cm y debe ser mayor a 0.",
                },
              })}
              error={errors.altura}
            />
          </ThreeColumRow>
          <div className="border p-5 rounded-lg ">
            <h2 className="mb-3">Patologicos</h2>

            <TwoColumRow>
              <div className="flex flex-col gap-2 px-5">
                <SwitchiWithField
                  checked={hta}
                  onChange={setHta}
                  label="HTA"
                  register={register("text_hta")}
                />
                <SwitchiWithField
                  checked={dm}
                  onChange={setDm}
                  label="DM"
                  register={register("text_dm")}
                />
                <SwitchiWithField
                  checked={icc}
                  onChange={setIcc}
                  label="ICC"
                  register={register("text_icc")}
                />
                <SwitchiWithField
                  checked={coronaria}
                  onChange={setCoronaria}
                  label="ENF. CORONARIA"
                  register={register("text_coronaria")}
                />
                <div className="pathologic-misc flex flex-col">
                  <div>
                    <Switchi 
                      checked={otros} 
                      onChange={setOtros} 
                      label="OTROS" />
                  </div>
                  <DinamicInput
                    visible={otros}
                    datos={otrosDescription}
                    setDatos={setOtrosDes}
                  />
                </div>
              </div>
              <div className="flex flex-col gap-2 px-5">
                <SwitchiWithField
                  checked={vascular}
                  onChange={setVascular}
                  label="ENF. VASCULAR PERIFERICA"
                  register={register("text_vascular")}
                />
                <SwitchiWithField
                  checked={pulmonar}
                  onChange={setPulmonar}
                  label="ENF. PULMONAR"
                  register={register("text_pulmonar")}
                />
                <SwitchiWithField
                  checked={arritmia}
                  onChange={setArritmia}
                  label="ARRITMIA"
                  register={register("text_arritmia")}
                />
                <SwitchiWithField
                  checked={dislipidemia}
                  onChange={setDislipidemia}
                  label="DISLIPIDEMIA"
                  register={register("text_dislipidemia")}
                />
              </div>
            </TwoColumRow>
          </div>

          <div className="border p-5 rounded-lg ">
            <h2 className="mb-3">Habitos y Costumbres</h2>

            <ThreeColumRow>
              <SwitchiWithField
                checked={fuma}
                onChange={setFuma}
                label="Fuma"
                register={register("text_fuma")}
              />
              <SwitchiWithField
                checked={vape}
                onChange={setVape}
                label="Vape"
                register={register("text_vape")}
              />
              <SwitchiWithField
                checked={alcohol}
                onChange={setAlcohol}
                label="Alcohol"
                register={register("text_alcohol")}
              />
              {/* <Switchi checked={fuma} onChange={setFuma} label="Fuma" />

              <Switchi checked={vape} onChange={setVape} label="Vape" />
              <Switchi
                checked={alcohol}
                onChange={setAlcohol}
                label="Alcohol"
              /> */}
            </ThreeColumRow>
          </div>
          <div className="border p-5 rounded-lg ">
            <TwoColumRow>
              <div className="my-4">
                <Switchi
                  checked={enfermedades}
                  onChange={setEnfermedades}
                  label="Hist. Fam. de Enfermedades"
                />
              </div>
              <DinamicInput
                visible={enfermedades}
                placeholder="Enfermedad"
                datos={enfermedadesDescription}
                setDatos={setEnfermedadesDes}
              />
            </TwoColumRow>
          </div>
          <div className="border p-5 rounded-lg ">
            <TwoColumRow>
              <div className="my-4">
                <Switchi
                  checked={alergias}
                  onChange={setAlergias}
                  label="Alergias"
                />
              </div>
              <DinamicInput
                visible={alergias}
                placeholder="Alergia"
                datos={alergiasDescription}
                setDatos={setAlergiasDes}
              />
            </TwoColumRow>
          </div>

          {/* allergies */}

          <div className="border p-5 rounded-lg ">
            <TwoColumRow>
              <div className="my-4">
                <Switchi
                  checked={medUso}
                  onChange={setMedUso}
                  label="Medicacion en Uso"
                />
              </div>
              <DinamicInput
                visible={medUso}
                placeholder="Medicamento"
                datos={medUsoDescription}
                setDatos={setMedUsoDes}
              />
            </TwoColumRow>
          </div>
          <div className="border p-5 rounded-lg ">
            <TwoColumRow>
              <div className="my-4">
                <Switchi
                  checked={otrosMed}
                  onChange={setOtrosMed}
                  label="Otros Medicamentos"
                />
              </div>
              <DinamicInput
                visible={otrosMed}
                placeholder="Medicamento"
                datos={otrosMedDescription}
                setDatos={setOtrosMedDes}
              />
            </TwoColumRow>
          </div>

          <div className="border p-5 rounded-lg ">
            <TwoColumRow>
              <div className="my-4">
                <Switchi
                  checked={quirurgicos}
                  onChange={setQuirurgicos}
                  label="Quirurgicos"
                />
              </div>
              <DinamicInput
                visible={quirurgicos}
                placeholder="Cirugia"
                datos={quirurgicosDescription}
                setDatos={setQuirurgicosDes}
              />
            </TwoColumRow>
          </div>
          {archivos?.length > 0 && (
            <div>
              <h2>Archivos adjuntos</h2>
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Nombre
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Archivo
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {archivos.split(",").map((file, index) => (
                    <tr key={index}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {file.split("/")?.at(-1)}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <a
                          href={file}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-subMain"
                        >
                          <span className="mr-2 inline-block">
                            <FileIcon extension={file.split(".").pop()} />
                          </span>
                          Descargar
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          <div className="flex gap-3 flex-col w-full col-span-6">
            <p className="text-sm">Archivos Adjuntos</p>
            <div className="grid xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 w-full">
              {files.length > 0 &&
                files.map((file, i) => (
                  <div className="relative w-full">
                    <img
                      src={file?.preview}
                      alt={file.name}
                      className="w-full  md:h-40 rounded-lg object-cover mt-5 p-1"
                      key={i}
                    />
                    <button
                      onClick={() => deleteFile(i)}
                      className="bg-white rounded-full w-8 h-8 flex-colo absolute -top-1 -right-1"
                    >
                      <FaTimes className="text-red-500" />
                    </button>
                  </div>
                ))}
            </div>

            <MultipleUploader
              setFile={setFiles}
              file={files}
              title="Arrastre sus archivos aqui"
              placeholder="Solo se aceptan archivos tipo imagen y documento"
            />
          </div>

          {/* submit */}
          <Button
            label={antecedent ? "Guardar" : "Registrar"}
            Icon={HiOutlineCheckCircle}
            onClick={handleSubmit(onSubmit)}
          />
        </div>
      )}
    </div>
  );
}

export default HealthInfomation;
