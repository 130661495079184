import {
  HiOutlineHome,
  HiOutlineMail,
  // HiOutlineUsers
} from 'react-icons/hi';
import {
  TbCalendar,
  TbChartHistogram,
  // TbFile,
  // TbFileInvoice,
  TbLockAccess,
  TbUsers,
} from 'react-icons/tb';
import { FaRegCalendarAlt, FaTelegramPlane, FaWhatsapp } from 'react-icons/fa';
import {
  RiFileList3Line,
  RiHeartLine,
  // RiImageLine,
  RiLockPasswordLine,
  // RiMedicineBottleLine,
  RiMoneyDollarCircleLine,
  // RiStethoscopeLine,
  // RiUserHeartLine,
  RiUserLine,
} from 'react-icons/ri';
import { GiChemicalDrop } from 'react-icons/gi';
import {
  MdListAlt,
  MdOutlineAttachMoney,
  // MdOutlineCampaign,
  // MdOutlineInventory2,
  MdOutlinePanoramaPhotosphereSelect,
  MdOutlineTextsms,
} from 'react-icons/md';
import { AiOutlineSetting } from 'react-icons/ai';
import { BiCalendar, BiUserPlus } from 'react-icons/bi';
import { IoNotifications } from 'react-icons/io5';

export const MenuDatas = [
  {
    title: 'Inicio',
    path: '/',
    icon: HiOutlineHome,
  },
  {
    title: 'Pacientes',
    path: '/patients',
    icon: TbUsers,
  },
  // {
  //   title: 'Receptions',
  //   path: '/receptions',
  //   icon: HiOutlineUsers,
  // },
  // {
  //   title: 'Doctors',
  //   path: '/doctors',
  //   icon: RiUserHeartLine,
  // },

  {
    title: 'Agenda',
    path: '/appointments',
    icon: FaRegCalendarAlt,
  },
  {
    title: 'Analisis y Laboratorios',
    path: '/laboratories',
    icon: GiChemicalDrop,
  },
  {
    title: 'Pagos',
    path: '/payments',
    icon: MdListAlt,
  },
  // {
  //   title: 'Invoices',
  //   path: '/invoices',
  //   icon: TbFileInvoice,
  // },
  // {
  //   title: 'Services',
  //   path: '/services',
  //   icon: MdOutlineInventory2,
  // },
  // {
  //   title: 'Medicine',
  //   path: '/medicine',
  //   icon: RiMedicineBottleLine,
  // },
  {
    title: 'Notificaciones',
    path: '/notifications',
    icon: IoNotifications,
  },
  {
    title: 'Configuracion',
    path: '/settings',
    icon: AiOutlineSetting,
  },
];
export const memberData = [
  {
    id: 1,
    title: 'Hugo Lloris',
    image: '/images/user1.png',
    admin: false,
    email: 'hugolloris@gmail.com',
    phone: '+1 234 567 890',
    age: 25,
    gender: 'Male',
    blood: 'A+',
    totalAppointments: 5,
    date: '20 Aug 2021',
  },
  {
    id: 2,
    title: 'Mauris auctor',
    image: '/images/user2.png',
    admin: false,
    email: 'maurisauctor@gmail.com',
    phone: '+1 456 789 123',
    age: 34,
    gender: 'Female',
    blood: 'B+',
    totalAppointments: 3,
    date: '22 Nov 2023',
  },
  {
    id: 3,
    title: 'Michael Owen',
    image: '/images/user3.png',
    admin: false,
    phone: '+1 890 123 456',
    email: 'michaelowen@gmail.com',
    age: 45,
    gender: 'Male',
    blood: 'O+',
    totalAppointments: 26,
    date: '12 Jan 2020',
  },
  {
    id: 4,
    title: 'Amina Smith',
    image: '/images/user4.png',
    admin: true,
    phone: '+1 908 765 432',
    email: 'aminasmith@gmail.com',
    age: 28,
    gender: 'Female',
    blood: 'AB+',
    totalAppointments: 17,
    date: '07 Feb 2001',
  },
  {
    id: 5,
    title: 'Minahil Khan',
    image: '/images/user5.png',
    admin: false,
    phone: '+1 890 123 456',
    email: 'minahilkhan@gmail.com',
    age: 35,
    gender: 'Female',
    blood: 'A+',
    totalAppointments: 9,
    date: '30 Dec 2019',
  },
  {
    id: 6,
    title: 'Alex Morgan',
    image: '/images/user6.png',
    admin: false,
    phone: '+1 908 765 432',
    email: 'alexmorgan@gmail.com',
    age: 29,
    gender: 'Male',
    blood: 'B+',
    totalAppointments: 34,
    date: '12 Jan 2020',
  },
  {
    id: 7,
    title: 'John Doe',
    image: '/images/user7.png',
    admin: false,
    phone: '+1 234 567 890',
    email: 'johndoe@gmail.com',
    age: 32,
    gender: 'Male',
    blood: 'O-',
    totalAppointments: 12,
    date: '18 Mar 2023',
  },
  {
    id: 8,
    title: 'David Beckham',
    image: '/images/user8.png',
    admin: false,
    phone: '+1 456 789 123',
    email: 'davidbackham@gmail.com',
    age: 27,
    gender: 'Female',
    blood: 'AB+',
    totalAppointments: 70,
    date: '01 June 2018',
  },
];

export const sortsDatas = {
  status: [
    {
      id: 1,
      name: 'Status...',
    },
    {
      id: 2,
      name: 'Pendiente',
    },
    {
      id: 3,
      name: 'Aprobado',
    },
    {
      id: 4,
      name: 'Cancelado',
    },
  ],
  method: [
    {
      id: 1,
      name: 'Payment method',
    },
    {
      id: 2,
      name: 'Cash',
    },
    {
      id: 3,
      name: 'NHCF Insurance',
    },
    {
      id: 4,
      name: 'Britam Insurance',
    },
  ],
  currency: [
    {
      id: 1,
      name: 'Select Currency',
    },
    {
      id: 2,
      name: 'USD (US Dollar)',
    },
    {
      id: 3,
      name: 'EUR (Euro)',
    },
    {
      id: 4,
      name: 'TSH (Tanzanian Shilling)',
    },
  ],
  instractions: [
    {
      id: 1,
      name: 'Cada 8 horas',
    },
    {
      id: 2,
      name: 'Despues de cada comida',
    },
    {
      id: 3,
      name: 'Antes de cada comida',
    },
  ],
  measure: [
    {
      id: 1,
      name: 'Select Measure',
    },
    {
      id: 2,
      name: 'mg',
    },
    {
      id: 3,
      name: 'ml',
    },
    {
      id: 4,
      name: 'gm',
    },
    {
      id: 5,
      name: 'kg',
    },
    {
      id: 6,
      name: 'lb',
    },
    {
      id: 7,
      name: 'tbsp',
    },
    {
      id: 8,
      name: 'tablet',
    },
    {
      id: 9,
      name: 'capsule',
    },
  ],
  stocks: [
    {
      id: 1,
      name: 'All',
    },
    {
      id: 2,
      name: 'Available',
    },
    {
      id: 3,
      name: 'Out of Stock',
    },
  ],
  service: [
    {
      id: 1,
      name: 'All',
    },
    {
      id: 2,
      name: 'Enabled',
    },
    {
      id: 3,
      name: 'Disabled',
    },
  ],
  title: [
    {
      id: 1,
      name: 'Dr.',
    },
    {
      id: 2,
      name: 'Mr.',
    },
    {
      id: 3,
      name: 'Mrs.',
    },
    {
      id: 4,
      name: 'Ms.',
    },
  ],
  filterPatient: [
    {
      id: 1,
      name: 'Ordenar por...',
    },
    {
      id: 2,
      name: 'Pacientes nuevos',
    },
    {
      id: 3,
      name: 'Pacientes antiguos',
    },
  ],
  genderFilter: [
    {
      id: 1,
      name: 'Femenino',
    },
    {
      id: 2,
      name: 'Masculino',
    },
    {
      id: 3,
      name: 'Otro'
    }
  ],
  bloodTypeFilter: [
    {
      id: 2,
      name: 'A+',
    },
    {
      id: 3,
      name: 'A-',
    },
    {
      id: 4,
      name: 'B+',
    },
    {
      id: 5,
      name: 'B-',
    },
    {
      id: 6,
      name: 'AB+',
    },
    {
      id: 7,
      name: 'AB-',
    },
    {
      id: 8,
      name: 'O+',
    },
    {
      id: 9,
      name: 'O-',
    },
  ],
  dosage: [
    {
      id: 1,
      name: 'Mañana (M)',
      value: 'morning',
    },
    {
      id: 2,
      name: 'Tarde (T)',
      value: 'afternoon',
    },
    {
      id: 3,
      name: 'Noche (N)',
      value: 'evening',
    },
  ],
};

export const campaignData = [
  {
    id: 1,
    title: 'Offer on Dental Checkup',
    date: '3 days ago',
    type: 'email',
    sendTo: 'All Patients',
    action: {
      subject: 'Delight patients with a free dental checkup',
      message:
        'Dear Patient, We are delighted to offer you a free dental checkup. Please visit our clinic to avail this offer. Thank you. and have a nice day. and welcome to our clinic.',
      subHeader: 'Offer on Dental Checkup',
      header: 'How to avail this offer?',
      footer: 'This offer is valid till 30th June, 2021',
    },
  },
  {
    id: 2,
    title: 'Britam Insurance Offer',
    date: '8 days ago',
    type: 'whatsapp',
    sendTo: 'Britam Patients',
    action: {
      message:
        'Hellow Patient, are you looking for a free dental checkup? We are delighted to offer you a free dental checkup. Please visit our clinic to avail this offer. Thank you.',
    },
  },
  {
    id: 3,
    title: 'NHCF Insurance Offer',
    date: '10 days ago',
    type: 'sms',
    sendTo: 'NHCF Patients',
    action: {
      message:
        'Hola, Delight patient with NHCF Insurance, We are delighted to offer you a free dental checkup. Please visit our clinic to avail this offer. Thank you.',
    },
  },
  {
    id: 4,
    title: 'Cash patients offer',
    date: '15 days ago',
    type: 'sms',
    sendTo: 'Cash Patients',
    action: {
      message:
        'Delight Patient, now get 50% off on dental checkup. Please visit our clinic to avail this offer. Thank you. and have a nice day. and welcome to our clinic.',
    },
  },
  {
    id: 5,
    title: 'Braces Offer',
    date: '12 days ago',
    type: 'email',
    sendTo: 'Britam Patients',
    action: {
      subject: 'Delight patients with a free dental checkup',
      message:
        'Dear Patient, Britam Insurance is delighted to offer you a free dental checkup. Please visit our clinic to avail this offer. Thank you. and have a nice day. and welcome to our clinic.',
      subHeader: 'Braces Offer for Britam Patients',
      header: 'Now get braces at 50% off',
      footer: 'This offer is valid till 30th June, 2021',
    },
  },
  {
    id: 6,
    title: 'Teeth Whitening Offer',
    date: '20 days ago',
    type: 'whatsapp',
    sendTo: 'All Patients',
    action: {
      message:
        'Hola, Delight patient with Teeth Whitening Offer, We are delighted to offer you a free dental checkup. Please visit our clinic to avail this offer. Thank you.',
    },
  },
];
export const servicesData = [
  {
    id: 2,
    name: 'Consulta',
    price: 40000,
    date: '23 June, 2021',
    status: true,
  },
  {
    id: 3,
    name: 'Re Consulta',
    price: 20000,
    date: '12 Jan, 2022',
    status: true,
  },
  {
    id: 4,
    name: 'Control',
    price: 50000,
    date: '11 April, 2023',
    status: false,
  },
  {
    id: 5,
    name: 'Analisis o Laboratorio',
    price: 34000,
    date: '10 Agst, 2021',
    status: true,
  }
];

export const invoicesData = [
  {
    id: 206719,
    to: memberData[5],
    total: 6070,
    createdDate: '12/06/2021',
    dueDate: '16/06/2021',
    items: [
      {
        id: 1,
        name: servicesData[0].name,
        price: 500,
        description:
          'Root Canal Treatment with X-Ray and Consultation is included in this package',
      },
      {
        id: 2,
        name: servicesData[0].name,
        price: 300,
        description: 'Teeth Whitening Treatment',
      },
      {
        id: 3,
        name: servicesData[1].name,
        price: 260,
        description: 'Dental Implants Treatment',
      },
      {
        id: 4,
        name: servicesData[2].name,
        price: 190000,
        description: 'Dental Crowns Treatment',
      },
      {
        id: 5,
        name: servicesData[3].name,
        price: 15000,
        description: 'Dental Bridges Treatment',
      },
    ],
  },
  {
    id: 198772,
    to: memberData[6],
    total: 5000,
    createdDate: '10/02/2023',
    dueDate: '14/02/2023',
    items: [
      {
        id: 1,
        name: servicesData[0].name,
        price: 190000,
        description: 'Dental Crowns Treatment',
      },
      {
        id: 2,
        name: servicesData[2].name,
        price: 15000,
        description: 'Dental Bridges Treatment',
      },
      {
        id: 3,
        name: servicesData[1].name,
        price: 20000,
        description: 'Dentures Treatment',
      },
      {
        id: 4,
        name: servicesData[3].name,
        price: 190000,
        description: 'Dental Crowns Treatment',
      },
    ],
  },
  {
    id: 456789,
    to: memberData[7],
    total: 10000,
    createdDate: '09/01/2023',
    dueDate: '13/01/2023',
    items: [
      {
        id: 1,
        name: servicesData[0].name,
        price: 5000,
        description: 'Dental Veneers Treatment',
      },
      {
        id: 2,
        name: servicesData[1].name,
        price: 16000,
        description: 'Dental Braces Treatment',
      },
      {
        id: 3,
        name: servicesData[2].name,
        price: 10000,
        description: 'Dental Sealants Treatment',
      },
      {
        id: 4,
        name: servicesData[3].name,
        price: 20000,
        description: 'Dentures Treatment',
      },
    ],
  },
  {
    id: 876543,
    to: memberData[4],
    total: 19000,
    createdDate: '08/01/2023',
    dueDate: '12/01/2023',
    items: [
      {
        id: 1,
        name: servicesData[0].name,
        price: 5000,
        description: 'Dental Veneers Treatment',
      },
      {
        id: 2,
        name: servicesData[1].name,
        price: 16000,
        description: 'Dental Braces Treatment',
      },
      {
        id: 3,
        name: servicesData[2].name,
        price: 10000,
        description: 'Dental Sealants Treatment',
      },
      {
        id: 4,
        name: servicesData[3].name,
        price: 20000,
        description: 'Dentures Treatment',
      },
      {
        id: 5,
        name: servicesData[3].name,
        price: 190000,
        description: 'Dental Crowns Treatment',
      },
      {
        id: 6,
        name: servicesData[1].name,
        price: 15000,
        description: 'Dental Bridges Treatment',
      },
    ],
  },
];

export const appointmentsData = [
  {
    id: 1,
    time: '+ 2 hrs',
    user: memberData[4],
    from: '10:00 AM',
    to: '12:00 PM',
    hours: 2,
    status: 'Pending',
    doctor: memberData[0],
    date: 'Jun 12, 2021',
  },
  {
    id: 2,
    time: '+ 1 hrs',
    user: memberData[5],
    from: '13:00 Pm',
    to: '18:00 PM',
    hours: 5,
    status: 'Cancel',
    doctor: memberData[1],
    date: 'Feb 24, 2021',
  },
  {
    id: 3,
    time: '- 2 hrs',
    user: memberData[6],
    from: '10:00 AM',
    to: '12:00 PM',
    hours: 2,
    status: 'Approved',
    doctor: memberData[2],
    date: 'Mar 12, 2023',
  },
  {
    id: 4,
    time: '- 3 hrs',
    user: memberData[7],
    from: '06:00 AM',
    to: '08:00 AM',
    hours: 3,
    status: 'Pending',
    doctor: memberData[3],
    date: 'Apr 06, 2023',
  },
  {
    id: 5,
    time: '- 4 hrs',
    user: memberData[3],
    from: '10:00 AM',
    to: '12:00 PM',
    hours: 7,
    status: 'Approved',
    doctor: memberData[4],
    date: 'May 18, 2023',
  },
];

export const transactionData = [
  {
    id: 1,
    user: memberData[0],
    date: 'Mar 12, 2022',
    amount: 1000,
    status: 'Paid',
    method: 'Cash',
    doctor: memberData[3],
  },
  {
    id: 2,
    user: memberData[1],
    date: 'Agus 12, 2023',
    amount: 2300,
    status: 'Paid',
    method: 'NHCF',
    doctor: memberData[4],
  },
  {
    id: 3,
    user: memberData[2],
    date: 'Jan 06, 2024',
    amount: 1200,
    status: 'Pending',
    method: 'Britam',
    doctor: memberData[5],
  },
  {
    id: 4,
    user: memberData[3],
    date: 'Feb 18, 2025',
    amount: 1400,
    status: 'Cancel',
    method: 'NHCF',
    doctor: memberData[6],
  },
  {
    id: 5,
    user: memberData[4],
    date: 'Mar 12, 2026',
    amount: 1230,
    status: 'Pending',
    method: 'Cash',
    doctor: memberData[7],
  },
  {
    id: 6,
    user: memberData[5],
    date: 'Apr 12, 2027',
    amount: 1000,
    status: 'Paid',
    method: 'NHCF',
    doctor: memberData[0],
  },
  {
    id: 7,
    user: memberData[6],
    date: 'May 12, 2028',
    amount: 8900,
    status: 'Cancel',
    method: 'Britam',
    doctor: memberData[1],
  },
  {
    id: 8,
    user: memberData[7],
    date: 'Jun 12, 2029',
    amount: 1000,
    status: 'Pending',
    method: 'Britam',
    doctor: memberData[2],
  },
];

export const dashboardCards = [
  {
    id: 1,
    title: 'Pacientes en Total',
    icon: TbUsers,
    value: 160,
    // percent: 45.06,
    color: ['bg-subMain', 'text-subMain', '#66B5A3'],
    datas: [9, 8, 5, 5, 9, 7, 7, 5, 10, 2, 9, 6],
  },
  {
    id: 2,
    title: 'Citas',
    icon: TbCalendar,
    value: 130,
    // percent: 25.06,
    color: ['bg-yellow-500', 'text-yellow-500', '#F9C851'],
    datas: [20, 50, 75, 15, 108, 97, 70, 41, 50, 20, 90, 60],
  },
  // {
  //   id: 3,
  //   title: 'Prescriptions',
  //   icon: TbFile,
  //   value: 4160,
  //   percent: 65.06,
  //   color: ['bg-green-500', 'text-green-500', '#34C759'],
  //   datas: [92, 80, 45, 15, 49, 77, 70, 51, 110, 20, 90, 60],
  // },
  {
    id: 4,
    title: 'Total Pagos',
    icon: MdOutlineAttachMoney,
    value: 45900,
    // percent: 45.06,
    color: ['bg-red-500', 'text-red-500', '#FF3B30'],
    datas: [200, 500, 750, 150, 1080, 970, 700, 410, 500, 200, 900, 600],
  },
];

export const notificationsData = [
  {
    id: 1,
    action: 1,
    user: memberData[0],
    time: 'Hace 2 horas',
  },
  {
    id: 2,
    action: 2,
    user: memberData[1],
    time: 'Hace 2 dias',
  },
  {
    id: 3,
    action: 1,
    user: memberData[2],
    time: 'Hace 3 dias',
  },
  {
    id: 4,
    action: 2,
    user: memberData[3],
    time: 'Hace 4 dias',
  },
];

export const shareData = [
  {
    id: 1,
    icon: HiOutlineMail,
    title: 'Email',
    description: 'Send to patient email address',
  },
  {
    id: 2,
    icon: MdOutlineTextsms,
    title: 'SMS',
    description: 'Send to patient phone number',
  },
  {
    id: 3,
    icon: FaWhatsapp,
    title: 'WhatsApp',
    description: 'Send to patient WhatsApp account',
  },
  {
    id: 4,
    icon: FaTelegramPlane,
    title: 'Telegram',
    description: 'Send to patient Telegram account',
  },
];

export const medicineData = [
  {
    id: 1,
    name: 'Paracetamol',
    measure: 'Tablet',
    stock: 400,
    price: 1000,
    status: 'Available',
    instraction: 'After meal',
  },
  {
    id: 2,
    name: 'Amoxicillin',
    measure: 'Capsule',
    stock: 200,
    price: 2300,
    status: 'Available',
    instraction: 'After meal',
  },
  {
    id: 3,
    name: 'Ibuprofen',
    measure: 'mm',
    stock: 0,
    price: 5000,
    status: 'Out of stock',
    instraction: 'Before meal',
  },
  {
    id: 4,
    name: 'Aspirin',
    measure: 'cm',
    stock: 370,
    price: 3500,
    status: 'Available',
    instraction: 'After meal',
  },
  {
    id: 5,
    name: 'Diazepam',
    measure: 'gm',
    stock: 0,
    price: 12000,
    status: 'Out of stock',
    instraction: 'Before meal',
  },
  {
    id: 6,
    name: 'Lorazepam',
    measure: 'mg',
    stock: 123,
    price: 15500,
    status: 'Available',
    instraction: 'Before meal',
  },
  {
    id: 7,
    name: 'Codeine',
    measure: 'ml',
    stock: 1,
    price: 30000,
    status: 'Available',
    instraction: 'After meal',
  },
  {
    id: 8,
    name: 'Tramadol',
    measure: 'lb',
    stock: 0,
    price: 200,
    status: 'Out of stock',
    instraction: 'Before meal',
  },
];

export const patientTab = [
  {
    id: 0,
    title: 'Vista 360°',
    icon: MdOutlinePanoramaPhotosphereSelect,
  },
  {
    id: 1,
    title: 'Historial Medico',
    icon: TbChartHistogram,
  },
  {
    id: 2,
    title: 'Agenda',
    icon: BiCalendar,
  },
  // {
  //   id: 3,
  //   title: 'Invoices',
  //   icon: RiFileList3Line,
  // },
  {
    id: 4,
    title: 'Pagos',
    icon: RiMoneyDollarCircleLine,
  },
  // {
  //   id: 5,
  //   title: 'Imagenes',
  //   icon: RiImageLine,
  // },
  // {
  //   id: 6,
  //   title: 'Antecedentes Clinicos',
  //   icon: RiStethoscopeLine,
  // },
  {
    id: 7,
    title: 'Informacion del paciente',
    icon: RiUserLine,
  },
  {
    id: 8,
    title: 'Antecedentes Clinicos',
    icon: RiHeartLine,
  },
];

export const doctorTab = [
  {
    id: 1,
    title: 'Personal Information',
    icon: RiUserLine,
  },
  {
    id: 2,
    title: 'Patients',
    icon: BiUserPlus,
  },
  {
    id: 3,
    title: 'Appointments',
    icon: BiCalendar,
  },
  {
    id: 4,
    title: 'Payments',
    icon: RiMoneyDollarCircleLine,
  },
  {
    id: 5,
    title: 'Invoices',
    icon: RiFileList3Line,
  },
  {
    id: 6,
    title: 'Access Control',
    icon: TbLockAccess,
  },
  {
    id: 7,
    title: 'Change Password',
    icon: RiLockPasswordLine,
  },
];

export const medicalRecodData = [
  {
    id: 1,
    date: '13, Enero 2023',
    amount: 150000,
    tipo: 'Consulta',
    data: [
      {
        id: 1,
        title: 'Sintomas',
        value: 'Tos, Dolor de Cabeza, emorragia',
      },
      {
        id: 2,
        title: 'Diagnostico',
        value: 'Resfrio, Pulmonia',
      },
      {
        id: 3,
        title: 'Prescripcion',
        value: 'Paracetamol, Amoxicillin, Ibuprofen, Aspirin',
      },
    ],
    attachments: [
      'https://placehold.it/300x300',
      'https://placehold.it/300x300',
      'https://placehold.it/300x300',
      'https://placehold.it/300x300',
    ],
    vitalSigns: [
      'Blood Pressure: 120/80 mmHg',
      'Pulse Rate: 80 bpm',
      'Respiratory Rate: 16 bpm',
      'Temperature: 36.5 °C',
      'Oxygen Saturation: 98%',
    ],
  },
  {
    id: 2,
    date: '13, Enero 2022',
    amount: 150000,
    tipo: 'Control',
    data: [
      {
        id: 2,
        title: 'Sintomas',
        value: 'Tos, Dolor de Cabeza, emorragia',
      },
      {
        id: 2,
        title: 'Diagnostico',
        value: 'Resfrio, Pulmonia',
      },
      {
        id: 3,
        title: 'Prescripcion',
        value: 'Paracetamol, Amoxicillin, Ibuprofen, Aspirin',
      },
    ],
    attachments: [
      'https://placehold.it/300x300',
      'https://placehold.it/300x300',
      'https://placehold.it/300x300',
      'https://placehold.it/300x300',
    ],
    vitalSigns: [
      'Blood Pressure: 120/80 mmHg',
      'Pulse Rate: 80 bpm',
      'Respiratory Rate: 16 bpm',
      'Temperature: 36.5 °C',
      'Oxygen Saturation: 98%',
    ],
  },
  {
    id: 3,
    date: '13, Enero 2021',
    amount: 150000,
    tipo: 'Reconsulta',
    data: [
      {
        id: 1,
        title: 'Sintomas',
        value: 'Tos, Dolor de Cabeza, emorragia',
      },
      {
        id: 2,
        title: 'Diagnostico',
        value: 'Resfrio, Pulmonia',
      },
      {
        id: 3,
        title: 'Prescripcion',
        value: 'Paracetamol, Amoxicillin, Ibuprofen, Aspirin',
      },
    ],
    attachments: [
      'https://placehold.it/300x300',
      'https://placehold.it/300x300',
      'https://placehold.it/300x300',
      'https://placehold.it/300x300',
    ],
    vitalSigns: [
      'Blood Pressure: 120/80 mmHg',
      'Pulse Rate: 80 bpm',
      'Respiratory Rate: 16 bpm',
      'Temperature: 36.5 °C',
      'Oxygen Saturation: 98%',
    ],
  },
];

export const doctorsData = [
  {
    id: 1,
    user: memberData[0],
    title: 'Dr.',
  },
  {
    id: 2,
    user: memberData[1],
    title: 'Dr.',
  },
  {
    id: 3,
    user: memberData[2],
    title: 'Dr.',
  },
  {
    id: 4,
    user: memberData[3],
    title: 'Dr.',
  },
];

export const receptionsData = [
  {
    id: 1,
    user: memberData[6],
    title: 'Dr.',
  },
  {
    id: 2,
    user: memberData[7],
    title: 'Dr.',
  },
  {
    id: 3,
    user: memberData[5],
    title: 'Dr.',
  },
  {
    id: 4,
    user: memberData[4],
    title: 'Dr.',
  },
  {
    id: 5,
    user: memberData[2],
    title: 'Dr.',
  },
  {
    id: 6,
    user: memberData[1],
    title: 'Dr.',
  },
];
