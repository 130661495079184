import { FaFile, FaFileAudio, FaFileExcel, FaFileImage, FaFilePdf, FaFileVideo, FaFileWord } from "react-icons/fa";

export const FileIcon = ({ extension }) => {
  switch (extension.toLowerCase()) {
    case "pdf":
      return <FaFilePdf />;
    case "doc":
    case "docx":
      return <FaFileWord />;
    case "xls":
    case "xlsx":
      return <FaFileExcel />;
    case "jpg":
    case "jpeg":
    case "png":
    case "gif":
      return <FaFileImage />;
    case "mp4":
    case "avi":
    case "mov":
    case "wmv":
    case "flv":
    case "mkv":
      return <FaFileVideo />;
    case "mp3":
    case "wav":
    case "ogg":
    case "flac":
      return <FaFileAudio />;
    default:
      return <FaFile />;
  }
};