import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiUrl } from "../apiUrl";

export const getCitas = createAsyncThunk("payments/getCita", async () => {
  const { data } = await axios.get(`${apiUrl}/api/v1/citas`);
  return data;
});

export const getCitasByPatientId = createAsyncThunk(
  "payments/getCitaById",
  async (id) => {
    const { data } = await axios.get(`${apiUrl}/api/v1/citas/${id}`);
    return data;
  }
);

export const updateCita = createAsyncThunk(
  "payments/updateCita",
  async (body) => {
    const fd = new FormData();
    for (const key in body) {
      const value = body[key];
      if (value !== undefined && value !== null) {
        fd.append(key, value);
      }
    }
    const { data } = await axios.post(`${apiUrl}/api/v1/citas/${body.id}`, fd);
    return data;
  }
);

export const createCita = createAsyncThunk(
  "payments/createCita",
  async (body) => {
    const fd = new FormData();
    for (const key in body) {
      const value = body[key];
      if (value !== undefined && value !== null) {
        fd.append(key, value);
      }
    }
    fd.append("estado", "en curso");

    const { data } = await axios.post(`${apiUrl}/api/v1/citas`, fd);
    return data;
  }
);

const initialState = {
  status: "idle",
  citas: [],
  error: null,
};

export const citaSlice = createSlice({
  name: "cita",
  initialState,
  reducers: {
    clear(state) {
      state.citas = [];
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getCitasByPatientId.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getCitasByPatientId.fulfilled, (state, action) => {
        state.status = "succeed";
        state.citas = action.payload;
      })
      .addCase(getCitasByPatientId.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(getCitas.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getCitas.fulfilled, (state, action) => {
        state.status = "succeed";
        state.citas = action.payload;
      })
      .addCase(getCitas.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateCita.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(updateCita.fulfilled, (state, action) => {
        state.status = "succeed";
        state.citas = [
          ...state.citas.filter((cita) => cita.id !== action.payload.id),
          action.payload,
        ];
      })
      .addCase(updateCita.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(createCita.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(createCita.fulfilled, (state, action) => {
        state.status = "succeed";
        state.citas = [...state.citas, action.payload];
      })
      .addCase(createCita.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { clear } = citaSlice.actions;

export default citaSlice.reducer;

export const getAllCitas = (state) => state.cita.citas;
export const getCitasStatus = (state) => state.cita.status;
export const getCitasError = (state) => state.cita.error;
