import React, { useEffect } from "react";
import Layout from "../Layout";
import {
  BsArrowDownLeft,
  BsArrowDownRight,
  BsArrowUpRight,
  BsCheckCircleFill,
  BsClockFill,
  BsXCircleFill,
} from "react-icons/bs";
import { DashboardBigChart, DashboardSmallChart } from "../components/Charts";
import {
  appointmentsData,
  dashboardCards,
  memberData,
  transactionData,
} from "../components/Datas";
import { Transactiontable } from "../components/Tables";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllStatistics,
  getStatistics,
} from "../redux/features/configurationSlice";
import { values } from "lodash";

const idCards = ["total_patients", "total_citas", "total_payments"];
const valCards = ["patients_by_month", "citas_by_month", "payments_by_month"];
function Dashboard() {
  const statistics = useSelector(getAllStatistics);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getStatistics());
  }, [dispatch]);

  return (
    <Layout>
      {/* boxes */}
      <h1 className="text-xl font-semibold mb-10">
        Sistema de Gestion de Pacientes
      </h1>
      <div className="w-full grid xl:grid-cols-3 gap-6 lg:grid-cols-3 sm:grid-cols-1 grid-cols-1">
        {dashboardCards.map((card, index) => (
          <div
            key={card.id}
            className=" bg-white rounded-xl border-[1px] border-border p-5"
          >
            <div className="flex gap-4 items-center">
              <div
                className={`w-10 h-10 flex-colo bg-opacity-10 rounded-md ${card.color[1]} ${card.color[0]}`}
              >
                <card.icon />
              </div>
              <h2 className="text-sm font-medium">{card.title}</h2>
            </div>
            <div className="grid grid-cols-8 gap-4 mt-4 bg-dry py-5 px-8 items-center rounded-xl">
              <div className="col-span-5">
                {/* statistc */}
                <DashboardSmallChart
                  data={statistics[valCards[index]]?.map((val) => val.valor)}
                  colors={card.color[2]}
                />
              </div>
              <div className="flex flex-col gap-4 col-span-3">
                <h4 className="text-lg font-medium">
                  {statistics[idCards[index]] ?? ""}
                  {
                    // if the id === 4 then add the $ sign
                    card.id === 4 ? "$" : "+"
                  }
                </h4>
                {/* <p className={`text-sm flex gap-2 ${card.color[1]}`}>
                  {card.percent > 50 && <BsArrowUpRight />}
                  {card.percent > 30 && card.percent < 50 && (
                    <BsArrowDownRight />
                  )}
                  {card.percent < 30 && <BsArrowDownLeft />}
                  {card.percent}
                </p> */}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="w-full my-6 grid xl:grid-cols-8 grid-cols-1 gap-6">
        <div className="xl:col-span-8  w-full">
          <div className="bg-white rounded-xl border-[1px] border-border p-5">
            <div className="mt-4">
              <h2 className="text-sm font-medium">Pacientes por Mes</h2>
              <DashboardBigChart datos={statistics?.patients_by_month} />
            </div>
          </div>
        </div>
        <div
          data-aos="fade-left"
          data-aos-duration="1000"
          data-aos-delay="10"
          data-aos-offset="200"
          className="xl:col-span-2 xl:block grid sm:grid-cols-2 gap-6"
        >
          {/* today apointments */}
          {/* <div className="bg-white rounded-xl border-[1px] border-border p-5 xl:mt-0">
            <h2 className="text-sm mb-4 font-medium">Citas para Hoy</h2>
            {appointmentsData.map((appointment, index) => (
              <div
                key={appointment.id}
                className="grid grid-cols-12 gap-2 items-center"
              >
                <p className="text-textGray text-[12px] col-span-3 font-light">
                  {appointment.time}
                </p>
                <div className="flex-colo relative col-span-2">
                  <hr className="w-[2px] h-20 bg-border" />
                  <div
                    className={`w-7 h-7 flex-colo text-sm bg-opacity-10
                   ${
                     appointment.status === 'Pending' &&
                     'bg-orange-500 text-orange-500'
                   }
                  ${
                    appointment.status === 'Cancel' && 'bg-red-500 text-red-500'
                  }
                  ${
                    appointment.status === 'Approved' &&
                    'bg-green-500 text-green-500'
                  }
                   rounded-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2`}
                  >
                    {appointment.status === 'Pending' && <BsClockFill />}
                    {appointment.status === 'Cancel' && <BsXCircleFill />}
                    {appointment.status === 'Approved' && <BsCheckCircleFill />}
                  </div>
                </div>
                <Link
                  to="/appointments"
                  className="flex flex-col gap-1 col-span-6"
                >
                  <h2 className="text-xs font-medium">
                    {appointment.user?.title}
                  </h2>
                  <p className="text-[12px] font-light text-textGray">
                    {appointment.from} - {appointment.to}
                  </p>
                </Link>
              </div>
            ))}
          </div> */}
        </div>
      </div>
    </Layout>
  );
}

export default Dashboard;
