import { BsGraphUp } from "react-icons/bs";
import { Button } from "../../components/Form";
import { useEffect, useState } from "react";
import Modal from "../../components/Modals/Modal";
import { ComparativeChart } from "../../components/Charts";
import { useDispatch, useSelector } from "react-redux";
import { getAllEstudiosGraficos, getEstudiosParaGrafico } from "../../redux/features/estudiosSlice";
import { useParams } from "react-router-dom";

const ExamResults = () => {
  const [open, setOpen] = useState(false);
  const [dataShow, setDataShow] = useState(null);
  const [name, setName] = useState('');
  const {id} = useParams();
  const dispatch = useDispatch();
  const datos = useSelector(getAllEstudiosGraficos);

  useEffect(() => {
    dispatch(getEstudiosParaGrafico(id));
  }, [dispatch, datos?.ldl?.length]);

  return (
    <div className="grid grid-cols-1 items-around justify-between gap-5">
      {open && (
        <Modal
          closeModal={() => setOpen(!open)}
          isOpen={open}
          title={`Comparacion de ${name}`}
        >
          <ComparativeChart name={name} data={dataShow} />
        </Modal>
      )}
      <div className="grid grid-cols-5">
        <div className="col-span-4 flex justify-start items-center">
          <p className="text-sm font-semibold">LD L </p>
        </div>
        <div className="col-span-1 w-10 h-10 flex justify-center items-center">
          <Button
            Icon={BsGraphUp}
            onClick={async () => {
              await setName('LD L');
              await setDataShow(datos?.ldl);
              setOpen(true);
            }}
          />
          
        </div>
      </div>
      <div className="grid grid-cols-5">
        <div className="col-span-4 flex justify-start items-center">
          <p className="text-sm font-semibold">Creatinina</p>
        </div>
        <div className="col-span-1 w-10 h-10 flex justify-center items-center">
          <Button
            Icon={BsGraphUp}
            onClick={async () => {
              await setName('Creatinina');
              await setDataShow(datos?.creatinina);
              setOpen(true);
            }}
          />
        </div>
      </div>
      <div className="grid grid-cols-5">
        <div className="col-span-4 flex justify-start items-center">
          <p className="text-sm font-semibold">Glicemia</p>
        </div>
        <div className="col-span-1 w-10 h-10 flex justify-center items-center">
          <Button
            Icon={BsGraphUp}
            onClick={async () => {
              await setName('Glicemia');
              await setDataShow(datos?.glicemia);
              setOpen(true);
            }}
          />
        </div>
      </div>
      <div className="grid grid-cols-5">
        <div className="col-span-4 flex justify-start items-center">
          <p className="text-sm font-semibold">Ácido Úrico</p>
        </div>
        <div className="col-span-1 w-10 h-10 flex justify-center items-center">
          <Button
            Icon={BsGraphUp}
            onClick={async () => {
              await setName('Ácido Úrico');
              await setDataShow(datos?.acido_urico);
              setOpen(true);
            }}
          />
        </div>
      </div>
      <div className="grid grid-cols-5">
        <div className="col-span-4 flex justify-start items-center">
          <p className="text-sm font-semibold">Hematocrito</p>
        </div>
        <div className="col-span-1 w-10 h-10 flex justify-center items-center">
          <Button
            Icon={BsGraphUp}
            onClick={async () => {
              await setName('Hematocrito');
              await setDataShow(datos?.hematocrito);
              setOpen(true);
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default ExamResults;
