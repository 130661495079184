import { useEffect, useState } from "react";
import { Button, Input, Switchi } from "../../components/Form";
import TwoColumRow from "../../components/TwoColumRow";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import {
  createEstudios,
  getEstudiosByCitaId,
  updateEstudios,
} from "../../redux/features/estudiosSlice";
import toast from "react-hot-toast";
import { FaTimes } from "react-icons/fa";
import MultipleUploader from "../../components/MultipleUploader";
import { FileIcon } from "../../components/FileIcon";

const laboratorioLabels = [
  "Hemoglobina - Hematocrito",
  "Urea",
  "Creatinina",
  "Glucosa",
  "Colesterol Total",
  "LDH",
  "Acido Úrico",
  "Hemoglobina Glicosilada",
  "Eritrosedimentacion",
  "Grupo Sanguineo y factor RH",
  "T. de Protombina(TP)",
  "T. Tromboplastina P. Activada (TTPA)",
  "Recuento de Plaquetas",
  "Orina Completa",
  "Proteinuria en Orina",
  "Glucosa Post Prandial",
  "Curva de Tolerancia a la Glucosa",
  "HDL Colesterol",
  "LDL Colesterol",
  "Triglicéridos",
  "Bilirrubinas",
  "GOT - Transaminasa",
  "GPT - Transanaminasa",
  "Fosfatasa Alcalina",
  "GGT",
  "Amilasa",
  "Proteinas Totales",
  "Albuminas",
  "Calcio Ionico",
  "Fósforo",
  "Magnesio",
  "Sodio",
  "Potasio",
  "Troponina I",
  "Hierro Sérico",
  "Ferritina",
  "Transferrina",
  "% de Saturación",
  "Vitamina B12",
  "Vitamina D25",
  "PCR",
  "Chagas IFI Ig G. lg M",
  "Chagas HAI",
  "PSA",
  "T3",
  "TSH",
  "T4 Libre",
];

const laboratorioKeys = [
  'hemoglobina_hematocrito',
  'urea',
  'creatinina',
  'glucosa',
  'colesterol_total',
  'ldh',
  'acido_urico',
  'hemoglobina_glicosilada',
  'eritrosedimentacion',
  'grupo_sanguineo_y_factor_rh',
  't_de_protombina_tp',
  't_tromboplastina_p_activada_ttpa',
  'recuento_de_plaquetas',
  'orina_completa',
  'proteinuria_en_orina',
  'glucosa_post_prandial',
  'curva_de_tolerancia_a_la_glucosa',
  'hdl_colesterol',
  'ldl_colesterol',
  'trigliceridos',
  'bilirrubinas',
  'got_transaminasa',
  'gpt_transaminasa',
  'fosfatasa_alcalina',
  'ggt',
  'amilasa',
  'proteinas_totales',
  'albuminas',
  'calcio_ionico',
  'fosforo',
  'magnesio',
  'sodio',
  'potasio',
  'troponina_i',
  'hierro_serico',
  'ferritina',
  'transferrina',
  'porcentaje_de_saturacion',
  'vitamina_b12',
  'vitamina_d25',
  'pcr',
  'chagas_ifi_ig_g_lg_m',
  'chagas_hai',
  'psa',
  't3',
  'tsh',
  't4_libre',
];

const Laboratorio = ({ data, onClose }) => {
  const [archivos, setArchivos] = useState(null);
  const [files, setFiles] = useState([]);
  const { register, handleSubmit, setValue } = useForm();

  const [values, setValues] = useState(
    laboratorioKeys.reduce((acc, key) => {
      acc[key] = false;
      return acc;
    }, {})
  );

  const [inputValues, setInputValues] = useState(
    laboratorioKeys.reduce((acc, key) => {
      acc[key] = "";
      return acc;
    }, {})
  );

  useEffect(() => {
    // Convierte los nombres de las claves a minúsculas y reemplaza los espacios por _
    const formattedData = Object.keys(data).reduce((acc, key) => {
      acc[key.toLowerCase().replace(/\s+/g, "_").replace(/[\(\)\.\,\-]/g, "")] = data[key];
      return acc;
    }, {});
    setArchivos(data.archivos);

    // Inicializa el estado de valores y valores de entrada con los datos del objeto
    setValues(
      laboratorioKeys.reduce((acc, key) => {
        acc[key] = formattedData[key] === 1;
        return acc;
      }, {})
    );

    laboratorioKeys.forEach((key) => {
      setValue(
        "resultado_" + key,
        formattedData["resultado_" + key] || ""
      );
    });
  }, [data, setValue]);

  const handleSwitchChange = (key) => (checked) => {
    setValues((prevValues) => ({
      ...prevValues,
      [key]: checked,
    }));
  };

  const dispatch = useDispatch();

  const onSubmit = async (body) => {
    Object.keys(values).forEach((key) => {
      body[key.toLowerCase()] = values[key];
    });
    body.cita_id = data?.cita_id;
    body.tipo = data?.tipo;
    body.archivos = archivos;
    body.files = files;
    let res = null;
    if (data?.id) {
      body.id = data?.id;
      res = await dispatch(updateEstudios(body));
    } else {

      res = await dispatch(createEstudios(body));
    }

    if (res?.error) {
      toast.error("No se pudo guardar la orden: " + res.error);
    } else {
      setFiles([]);
      toast.success("Orden Guardada con exito!");
      dispatch(getEstudiosByCitaId(data.cita_id));
      onClose();
    }
  };

  const deleteFile = (index) => {
    setFiles(files.filter((file, i) => index !== i));
  };

  return (
    <>
      <div className="h-96 overflow-y-scroll border px-5 mb-5 pb-5 rounded-lg">
        {laboratorioLabels.map((label, index) => {
          const key = laboratorioKeys[index];
          return (
            <div key={index} className="flex">
              <div className="w-2/3 pt-6">
                <Switchi
                  checked={values[key]}
                  onChange={handleSwitchChange(key)}
                  label={label}
                />
              </div>
              <div className="w-[30%]">
                {values[key] && (
                  <Input
                    color={true}
                    register={register("resultado_" + key)}
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
      {archivos?.length > 0 && (
        <div>
          <h2>Archivos adjuntos</h2>
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Nombre
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Archivo
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {archivos.split(",").map((file, index) => (
                <tr key={index}>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">
                      {file.split("/")?.at(-1)}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <a
                      href={file}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-subMain"
                    >
                      <span className="mr-2 inline-block">
                        <FileIcon extension={file.split(".").pop()} />
                      </span>
                      Descargar
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <div className="flex gap-3 flex-col w-full col-span-6 my-2">
        <p className="text-sm">Archivos Adjuntos</p>
        <div className="grid xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 w-full">
          {files.length > 0 &&
            files.map((file, i) => (
              <div className="relative w-full">
                <img
                  src={file?.preview}
                  alt={file.name}
                  className="w-full  md:h-40 rounded-lg object-cover mt-5 p-1"
                  key={i}
                />
                <button
                  onClick={() => deleteFile(i)}
                  className="bg-white rounded-full w-8 h-8 flex-colo absolute -top-1 -right-1"
                >
                  <FaTimes className="text-red-500" />
                </button>
              </div>
            ))}
        </div>

        <MultipleUploader
          setFile={setFiles}
          file={files}
          title="Arrastre sus archivos aqui"
          placeholder="Solo se aceptan archivos tipo imagen y documento"
        />
      </div>
      <Button label="Guardar" onClick={handleSubmit(onSubmit)} />
    </>
  );
};

export default Laboratorio;