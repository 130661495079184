import { useEffect, useState } from "react";
import { Button, Input, Switchi } from "../../components/Form";
import TwoColumRow from "../../components/TwoColumRow";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { createEstudios, getEstudiosByCitaId, updateEstudios } from "../../redux/features/estudiosSlice";
import toast from "react-hot-toast";
import MultipleUploader from "../../components/MultipleUploader";
import { FaTimes } from "react-icons/fa";
import { FileIcon } from "../../components/FileIcon";

const Ecocardiograma = ({data, onClose}) => {
  const [archivos, setArchivos] = useState(null);
  const [files, setFiles] = useState([]);

  const [switchStates, setSwitchStates] = useState({
    DDVI: false,
    DSVI: false,
    SIV: false,
    FE: false,
    MASA_VI: false,
    DAo: false,
    DAI: false,
    VOL_AI: false,
    VOL_AI_SC: false,
    TAPSE: false,
    SLG: false,
    PSAP: false,
    VMI: false,
    VAo: false,
    VTri: false,
    VPul: false,
  });

  const dispatch = useDispatch();

  const {
    register,
    setValue, 
    handleSubmit
  } = useForm();

  const handleSwitchChange = (name, value) => {
    setSwitchStates(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };
  useEffect(() => {
    if (data?.id) {
      const initialSwitchStates = {};
      Object.keys(switchStates).forEach((key) => {
          const switchName = key.toLowerCase();
          initialSwitchStates[switchName] = !!data[switchName];
          setValue("resultado_" + switchName, data["resultado_" + switchName] ?? "");
      });
      setArchivos(data.archivos);
      setSwitchStates(initialSwitchStates);
    }
  }, [data, setValue]);
  const renderRow = (label) => (
    <TwoColumRow key={label}>
      <div className="pt-6">
        <Switchi
          checked={switchStates[label]}
          onChange={(value) => handleSwitchChange(label, value)}
          label={label.toUpperCase()}
        />
      </div>
      {switchStates[label] && (
        <Input
          
          color={true}
          register={register("resultado_" + label.toLowerCase())}
        />
      )}
    </TwoColumRow>
  );

  const onSubmit = async (body) => {
    Object.keys(switchStates).forEach((key) => {
      body[key.toLowerCase()] = switchStates[key];
    });
    body.cita_id = data?.cita_id;
    body.tipo = data?.tipo;
    body.nombre = data?.nombre;
    body.archivos = archivos;
    body.files = files;
    let res = null;
    if(data?.id){
      body.id = data?.id;
      res = await dispatch(updateEstudios(body));
    } else {
      res = await dispatch(createEstudios(body));
    }
    if (res?.error) {
      toast.error('No se pudo guardar la orden: '+ res.error)
    } else {
      setFiles([]);
      toast.success("Orden Guardada con exito!");
      dispatch(getEstudiosByCitaId(data.cita_id));
      onClose();
    }
  }

  const deleteFile = (index) => {
    setFiles(files.filter((file, i) => index !== i));
  };

  return (
    <>
      <div className="h-96 overflow-y-scroll border px-5 mb-5 pb-5 rounded-lg"> 
        {Object.keys(switchStates).map(renderRow)}
      </div>

      {archivos?.length > 0 && (
        <div>
          <h2>Archivos adjuntos</h2>
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Nombre
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Archivo
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {archivos.split(",").map((file, index) => (
                <tr key={index}>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">
                      {file.split("/")?.at(-1)}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <a
                      href={file}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-subMain"
                    >
                      <span className="mr-2 inline-block">
                        <FileIcon extension={file.split(".").pop()} />
                      </span>
                      Descargar
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <div className="flex gap-3 flex-col w-full col-span-6 my-2">
        <p className="text-sm">Archivos Adjuntos</p>
        <div className="grid xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 w-full">
          {files.length > 0 &&
            files.map((file, i) => (
              <div className="relative w-full">
                <img
                  src={file?.preview}
                  alt={file.name}
                  className="w-full  md:h-40 rounded-lg object-cover mt-5 p-1"
                  key={i}
                />
                <button
                  onClick={() => deleteFile(i)}
                  className="bg-white rounded-full w-8 h-8 flex-colo absolute -top-1 -right-1"
                >
                  <FaTimes className="text-red-500" />
                </button>
              </div>
            ))}
        </div>

        <MultipleUploader
          setFile={setFiles}
          file={files}
          title="Arrastre sus archivos aqui"
          placeholder="Solo se aceptan archivos tipo imagen y documento"
        />
      </div>
      <Button label={data?.id ? "Guardar Cambios": "Generar Orden"} onClick={handleSubmit(onSubmit)} />
    </>
  );
};

export default Ecocardiograma;
