import ReactLoading from 'react-loading';

const Loading = () => {
  return (
    <div className="w-full h-full flex justify-center items-center">
      <ReactLoading type='spin' color='#66B5A3' width={100} height={100} />

    </div>
  )
};
export default Loading;