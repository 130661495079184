import React from 'react';
import MainPatientDrawer from './MainPatientDrawer';
import PatientSidebar from '../../LayoutPatient/Sidebar';

function MenuPatientDrawer({ isOpen, toggleDrawer }) {
  return (
    <MainPatientDrawer isOpen={isOpen} toggleDrawer={toggleDrawer}>
      <PatientSidebar />
    </MainPatientDrawer>
  );
}

export default MenuPatientDrawer;
