import { Listbox, Menu, Switch } from "@headlessui/react";
import React, { useEffect, useState, useCallback } from "react";
import { BiLoaderCircle } from "react-icons/bi";
import DatePicker from "react-datepicker";
import _ from "lodash";
import { FaCheck } from "react-icons/fa";
import {
  RiAccountCircleFill,
  RiAddCircleFill,
  RiMic2Fill,
  RiMicLine,
  RiSearch2Line,
  RiDeleteBinLine,
} from "react-icons/ri";
import { set } from "react-hook-form";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import toast from "react-hot-toast";
import { getVisita } from "../redux/features/visitasSlice";
import TwoColumRow from "./TwoColumRow";


export function Input({
  label,
  name,
  type,
  color,
  placeholder,
  register,
  disabled,
  error,
  value,
}) {
  return !register ? (
    <div className="text-sm w-full">
      <label
        className={`${
          color ? "text-black text-sm" : "text-white font-semibold"
        } `}
      >
        {label}
      </label>
      <input
        disabled={disabled}
        name={name}
        type={type}
        value={value}
        placeholder={placeholder}
        className="w-full bg-transparent text-sm mt-3 p-4 border font-lig rounded-lg border-border"
      />
    </div>
  ) : (
    <div className="text-sm w-full">
      <label
        className={`${
          color ? "text-black text-sm" : "text-white font-semibold"
        } `}
      >
        {label}
      </label>
      <input
        disabled={disabled}
        name={name}
        {...register}
        type={type}
        placeholder={placeholder}
        className={`w-full bg-transparent text-sm mt-3 p-4 border ${
          error
            ? "border-red-500"
            : color
            ? "border-border font-light"
            : "border-white text-white"
        } rounded-lg focus:border focus:${
          error ? "border-red-600" : "border-subMain"
        }`}
      />
      {error && (
        <span className=" text-start text-red-500 font-extralight text-xs">
          {error.message}
        </span>
      )}
    </div>
  );
}

export function DinamicInput({
  label,
  name,
  type,
  color,
  placeholder,
  disabled,
  visible,
  datos = "",
  setDatos,
  defaultValue,
}) {
  const [data, setData] = useState([]);
  const [newIn, setNewIn] = useState("");
  useEffect(() => {
    if (datos) {
      setData(datos.split(","));
      setNewIn("");
    } 
    else {
      if (data.length === 0)
        setNewIn(defaultValue);
    }
  }, [datos]);
  const handlePush = () => {
    if (newIn === "") 
      return;
    setDatos(data + "," + newIn);
    setNewIn("");
  };
  return (
    <>
      {Boolean(visible) && (
        <div className="text-sm w-full">
          <div className="">
            <label
              className={`${
                color ? "text-black text-sm" : "text-white font-semibold"
              }`}
            >
              {label}
            </label>
            <input
              disabled={disabled}
              name={name}
              type={type}
              value={newIn}
              onChange={(e) => setNewIn(e.target.value)}
              placeholder={placeholder}
              className="w-full bg-transparent text-sm mb-3 mt-2 p-4 border font-lig rounded-lg border-border"
            />
            <Button
              className=""
              label="Agregar"
              Icon={RiAddCircleFill}
              onClick={handlePush}
            />
          </div>
          <ul className="border rounded-sm">
            {data.map((el, index) => (
              <li
                className={`${index % 2 ? "bg-white" : "bg-slate-50"} p-2`}
                key={el}
              >
                {el}
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
}

export function DinamicInputWithSearchBar({
  label,
  name,
  type,
  color,
  placeholder,
  disabled,
  visible,
  datos = "",
  setDatos,
  defaultValue,
  dataSearch,
}) {
  const [data, setData] = useState([]);
  const [newIn, setNewIn] = useState("");
  const [filteredData, setFilteredData] = useState(dataSearch.slice(0, 10));
  const [visilbeData, setVisibleData] = useState(false);
  
  useEffect(() => {
    if (datos) {
      setData(datos.split("|"));
      setNewIn("");
    } 
    else if (data.length === 0) 
      setNewIn(defaultValue);
  }, [datos]);

  useEffect(() => {
    setDatos(data.join("|"));
  }, [data]);

  const handlePush = () => {
    if (newIn){
      setDatos([...data, newIn].join("|"));
      setNewIn("");
    }
  };

  const handleBlur = () => {
    setTimeout(() => setVisibleData(false), 400);
  };

  
  const handleChange = (e) => {
    setNewIn(e.target.value);
    if (newIn.length) {
      let filtered = dataSearch.filter(
        (item) => item.id.toLowerCase().includes(newIn.toLowerCase()) || item.enfermedad.toLowerCase().includes(newIn.toLowerCase()),
      );
      if (filtered.length > 10)
        filtered = filtered.slice(0, 10);
      setFilteredData(filtered);
    }
    else
      setFilteredData(dataSearch.slice(0, 10));
  };

  const handleDelete = useCallback((item) => {
    setData(data.filter((el) => el !== item));
  }, [data]);
  
  return (
    <>
      {Boolean(visible) && (
        <div className="text-sm w-full">
          <div className="">
            <label
              className={`${
                color ? "text-black text-sm" : "text-white font-semibold"
              }`}
            >
              {label}
            </label>
            <input
              disabled={disabled}
              name={name}
              type={type}
              value={newIn}
              onChange={handleChange}
              placeholder={placeholder}
              onFocus={() => setVisibleData(true)}
              onBlur={handleBlur}
              className={`w-full bg-transparent text-sm mb-3 mt-2 p-4 border font-lig rounded-lg border-border`}
            />
            {visilbeData && (
              <ul className="max-h-40 w-full sm:w-1/2 -mt-3 border absolute rounded-lg z-50 bg-white overflow-y-scroll">
                {filteredData?.map((item) => (
                  <li
                    key={item.id}
                    onClick={() => setNewIn(`${item.id} - ${item.enfermedad}`)}
                    className="border-b py-2 px-5"
                  >
                    {`${item.id} - ${item.enfermedad}`}
                  </li>
                ))}
              </ul>
            )}

            <Button
              className=""
              label="Agregar"
              Icon={RiAddCircleFill}
              onClick={handlePush}
            />
          </div>
          <ul className="border rounded-sm newt">
            {data.length > 0 && (
              <li
                className="bg-white p-2"
              ></li> 
            )}
            {data.length > 0 &&
              data.map((el, index) => (
                <li
                  className={`${index % 2 ? "bg-white": "bg-slate-50"} p-2 flex justify-between items-center`} 
                  key={el}
                >
                  <p className="">{el}</p>
                  <button className=" bg-red-600 bg-opacity-5 text-red-600 rounded-lg border border-red-100 py-2 px-8 text-sm" onClick={() => handleDelete(el)}>
                    <RiDeleteBinLine/>
                  </button>
                </li>
              ))
            }
          </ul>
        </div>
      )}
    </>
  );
}

export function SearchInput({
  label,
  name,
  type,
  color,
  placeholder,
  register,
  disabled,
  error,
  onChange,
  value,
}) {
  return (
    <div className="text-sm w-full">
      <label
        className={`${
          color ? "text-black text-sm" : "text-white font-semibold"
        } `}
      >
        {label}
      </label>
      <div className="">
        <input
          disabled={disabled}
          name={name}
          type={type}
          value={value}
          placeholder={placeholder}
          className="w-full bg-transparent text-sm my-3 p-4 border font-lig rounded-lg border-border"
        />
        <Button className="" label="Buscar" Icon={RiSearch2Line} />
      </div>
    </div>
  );
}
// button

export function Button({ label, onClick, loading, Icon }) {
  return (
    <button
      disabled={loading}
      onClick={onClick}
      className={`w-full flex-rows gap-4 hover:opacity-80 transitions bg-subMain text-white text-sm font-medium px-2 py-4 rounded`}
    >
      {loading ? (
        <BiLoaderCircle className="animate-spin text-white text-2xl" />
      ) : (
        <>
          {label}
          {Icon && <Icon className="text-white text-xl" />}
        </>
      )}
    </button>
  );
}

// select

export function MenuSelect({ children, datas, item: data }) {
  return (
    <div className="text-sm w-full relative">
      <Menu>
        <Menu.Button>{children}</Menu.Button>
        <Menu.Items className="flex flex-col z-50 gap-4 absolute -left-[73%] -top-[140%]  bg-white rounded-md shadow-lg py-4 px-3 ring-1 ring-border focus:outline-none">
          {datas.map((item, index) => (
            <button
              onClick={() => item.onClick(data.id)}
              key={index}
              className={`flex gap-4 items-center hover:text-subMain`}
            >
              {item.icon && <item.icon className="text-md text-subMain" />}
              {item.title}
            </button>
          ))}
        </Menu.Items>
      </Menu>
    </div>
  );
}

export function MenuSelect2({ children, datas, item: data }) {
  return (
    <div className="text-sm w-full relative">
      <Menu>
        <Menu.Button>{children}</Menu.Button>
        <Menu.Items className="flex flex-col z-50 gap-4 absolute -left-[73%]  bg-white rounded-md shadow-lg py-4 px-3 ring-1 ring-border focus:outline-none">
          {datas.map((item, index) => (
            <button
              onClick={() => item.onClick(data)}
              key={index}
              className={`flex gap-4 items-center hover:text-subMain`}
            >
              {item.icon && <item.icon className="text-md text-subMain" />}
              {item.title}
            </button>
          ))}
        </Menu.Items>
      </Menu>
    </div>
  );
}

// select 2

export function Select({ children, selectedPerson, setSelectedPerson, datas }) {
  return (
    <div className="text-sm relative w-full ">
      <div className="w-full">
        <Listbox value={selectedPerson} onChange={setSelectedPerson}>
          <Listbox.Button className={"w-full"}>{children}</Listbox.Button>
          <Listbox.Options className="flex  flex-col gap-4 top-10 z-50 absolute left-0 w-full bg-white rounded-md shadow-lg py-4 px-6 ring-1 ring-border focus:outline-none">
            {datas.map((person) => (
              <Listbox.Option
                className={`cursor-pointer text-xs hover:text-subMain`}
                key={person.id}
                value={person}
                disabled={person.unavailable}
              >
                {person.name}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Listbox>
      </div>
    </div>
  );
}

// switch

export function Switchi({ checked, onChange, label }) {
  return (
    <TwoColumRow className="flex gap-5 ">
      <Switch
        checked={checked}
        onChange={onChange}
        className={`${checked ? "bg-subMain" : "bg-border"}
        relative inline-flex p-[2px] w-12 cursor-pointer rounded-full transitions flex-shrink-0`}
      >
        <span
          aria-hidden="true"
          className={`${checked ? "translate-x-5" : "translate-x-0"}
          pointer-events-none inline-block h-6 w-6 transform rounded-full bg-white shadow-lg  transitions`}
        />
      </Switch>
      <label>{label}</label>
    </TwoColumRow>
  );
}

export function SwitchiWithField({ checked, onChange, label, name, register }) {
  return (
    <div className="flex flex-col gap-5">
      <Switchi checked={checked} onChange={onChange} label={label} />
      {Boolean(checked) && (
        <Input
          label={`Descripcion ${label}`}
          color={true}
          name={name}
          register={register}
        />
      )}
    </div>
  );
}

// textarea

export function Textarea({ label, name, register, setValue, getValue, placeholder, rows, error }) {
  const [onPress, setOnPress] = useState(false);
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();
  const startListening = () => {
    setOnPress(true);
    resetTranscript('');
    SpeechRecognition.startListening({ continuous: true });
  
  }
  const stopListening = () => {
    SpeechRecognition.stopListening();
    const prev = getValue(name);
    setValue(name, (prev ?? '') + ' ' + transcript);
    setOnPress(false);
  }
  if (!browserSupportsSpeechRecognition) {
    toast.error('El navegador no soporta reconocimiento de voz!');
  }

  return (
    <div className="text-sm w-full relative">
      <label className={"text-black text-sm"}>{label}</label>
      <textarea
        name={name}
        rows={rows}
        {...register}
        placeholder={placeholder}
        className={` focus:border-subMain ${
          error?.message ? "border-red-500 focus:border-red-500" : ""
        } w-full bg-transparent text-sm mt-3 p-4 border border-border rounded font-light 
         `}
      />
      {error && (
        <span className=" text-start text-red-500 font-extralight text-xs">
          {error.message}
        </span>
      )}
      <button 
        onTouchStart={startListening}
        onMouseDown={startListening}
        onTouchEnd={stopListening}
        onMouseUp={stopListening}
        className={`absolute bottom-5 right-5 ${listening && onPress ? 'bg-red-500':'bg-subMain'} p-3 rounded-lg text-white`}
        >
        <RiMic2Fill />
      </button>
    </div>
  );
}

// date picker

export function DatePickerComp({ label, startDate, onChange }) {
  const years = _.range(1940, new Date().getFullYear() + 5);
  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  return (
    <div className="text-sm w-full">
      <label className={"text-black text-sm block"}>{label}</label>
      <DatePicker
        selected={startDate ? startDate : new Date()}
        onChange={onChange}
        dateFormat="dd/MM/yyyy"
        className="w-full bg-transparent text-sm mt-3 p-4 border border-border font-light rounded-lg focus:border focus:border-subMain block"
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            style={{
              margin: 10,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <button
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
              className="p-2 hover:bg-subMain hover:text-white rounded-lg"
            >
              {"⬅"}
            </button>
            <select
              value={date.getFullYear()}
              onChange={({ target: { value } }) => changeYear(value)}
              className="p-2 pr-5 rounded-lg mx-2"
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <select
              value={months[date.getMonth()]}
              onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
              }
              className="p-2 pr-5 rounded-lg mx-2"
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <button
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
              className="px-2 hover:bg-subMain hover:text-white rounded-lg"
            >
              {"⮕"}
            </button>
          </div>
        )}
      />
    </div>
  );
}

// time picker

export function TimePickerComp({ label, startDate, onChange }) {
  return (
    <div className="text-sm w-full flex flex-col">
      <label className={"text-black text-sm"}>{label}</label>
      <DatePicker
        selected={startDate}
        onChange={onChange}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={30}
        timeCaption="Hora"
        dateFormat="h:mm aa"
        className="w-full bg-transparent text-sm mt-3 p-4 border border-border bg-white font-light rounded-lg focus:border focus:border-subMain"
      />
    </div>
  );
}

// checkbox

export function Checkbox({ label, name, onChange, checked }) {
  return (
    <div className="text-sm w-full flex flex-row items-center">
      {/* design checkbox */}
      <label className="flex-colo cursor-pointer relative">
        <input
          type="checkbox"
          name={name}
          checked={checked}
          onChange={onChange}
          className="absolute opacity-0 w-0 h-0"
        />
        <span
          className={` border rounded  w-5 h-5 flex flex-shrink-0 justify-center items-center mr-2 ${
            checked ? "border-subMain bg-subMain" : "border-gray-300 bg-white"
          }`}
        >
          <FaCheck
            className={`text-[10px] ${checked ? "block text-white" : "hidden"}`}
          />
        </span>
      </label>

      {label && <p className={"text-black text-xs ml-2"}>{label}</p>}
    </div>
  );
}

// from to date picker

export function FromToDate({ label, startDate, onChange, endDate, bg }) {
  const years = _.range(1940, new Date().getFullYear() + 1);
  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  return (
    <div className="text-sm w-full flex flex-col gap-2">
      {label && <label className={"text-black text-sm"}>{label}</label>}

      {/* <DatePicker
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        onChange={onChange}
        dateFormat="dd/MM/yyyy"
        className={`w-full ${
          bg ? bg : "bg-transparent"
        }  text-xs px-4 h-14 border border-border text-main font-normal rounded-lg focus:border focus:border-subMain`}
      /> */}
      <DatePicker
      selected={startDate}
      onChange={onChange}
      startDate={startDate}
      endDate={endDate}
      selectsRange
      dateFormat="dd/MM/yyyy"
      className={`w-full ${
        bg ? bg : "bg-transparent"
      }  text-xs px-4 h-14 border border-border text-main font-normal rounded-lg focus:border focus:border-subMain`}
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div
          style={{
            margin: 10,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <button
            onClick={decreaseMonth}
            disabled={prevMonthButtonDisabled}
            className="p-2 hover:bg-subMain hover:text-white rounded-lg"
          >
            {'⬅'}
          </button>
          <select
            value={date.getFullYear()}
            onChange={({ target: { value } }) => changeYear(value)}
            className="p-2 pr-5 rounded-lg mx-2"
          >
            {years.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          <select
            value={months[date.getMonth()]}
            onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
            className="p-2 pr-5 rounded-lg mx-2"
          >
            {months.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          <button
            onClick={increaseMonth}
            disabled={nextMonthButtonDisabled}
            className="px-2 hover:bg-subMain hover:text-white rounded-lg"
          >
            {'⮕'}
          </button>
        </div>
      )}
    />
      
    </div>
  );
}
