import { useEffect } from "react";
import LayoutPatient from "../../LayoutPatient";
import { LaboratoryTable } from "../../components/Tables";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteEstudio,
  getAllEstudiosByPatient,
  getEstudiosByPatientId,
} from "../../redux/features/estudiosSlice";
import ConfirmationAlert from "../../components/ConfirmationAlert";
import OrdenPdf from "../../pdf/OrdenPdf";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import { getAge } from "../../utils/age";

function PatientLaboratories() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const estudios = useSelector(getAllEstudiosByPatient);

  useEffect(() => {
    dispatch(getEstudiosByPatientId(id));
  }, [estudios?.length, dispatch, id]);

  return (
    <LayoutPatient>
      <h1 className="text-xl font-semibold m-5">Analisis y Laboratorios</h1>
      <div className="w-full justify-center items-center p-10 overflow-auto bg-white rounded-lg border">
        <LaboratoryTable
          id={id}
          data={estudios}
          functions={{
            delete: (body) => {
              ConfirmationAlert({
                title: "Eliminar Orden",
                text: "Esta seguro de eliminar la orden?",
                onAccept: async () => {
                  const res = await dispatch(deleteEstudio(body));
                  if (res?.error) {
                    toast.error("No se pudo eliminar la orden, " + res.error);
                  } else {
                    dispatch(getEstudiosByPatientId(id));
                    toast.success("Orden eliminada correctamente!");
                  }
                },
              });
            },
            printPdf: async (body) => {
              const blob = await pdf(
                <OrdenPdf
                  data={{
                    nombre: `${body?.cita?.patient?.persona?.nombre} ${body?.cita?.patient?.persona?.apellidos}`,
                    edad: `${
                      body?.cita?.patient?.persona?.fecha_nacimiento
                        ? getAge(
                            new Date(
                              body?.cita?.patient?.persona?.fecha_nacimiento
                            )
                          ) + " años"
                        : "No disponible"
                    }`,
                    fecha: new Date().toLocaleDateString("en-GB"),
                    lugarAtencion:
                      body?.cita?.visita?.lugar_atencion ?? "No disponible",
                    seguro: `${
                      body?.cita?.patient?.empresa_seg ?? "No disponible"
                    }`,
                    orden: body,
                  }}
                />
              ).toBlob();
              saveAs(
                blob,
                `Orden-${body?.id}-${new Date().toLocaleDateString(
                  "en-GB"
                )}.pdf`
              );
            },
          }}
          button={true}
        />
      </div>
    </LayoutPatient>
  );
}

export default PatientLaboratories;
