import LayoutPatient from "../../LayoutPatient";
import MedicalRecord from "./MedicalRecord";
import { useParams } from "react-router-dom";
import { getAge } from "../../utils/age";
import { usePatient } from "../../customHooks/queries/usePatient";
import Loading from "../Loading";

function PatientHistory() {
  const { id } = useParams();
  const { data: patient, isLoading } = usePatient(id);

  if (isLoading) return <Loading />;

  return (
    <LayoutPatient title="">
      <div className=" grid grid-cols-12 gap-2  items-start">
        <div
          data-aos="fade-left"
          data-aos-duration="1000"
          data-aos-delay="100"
          data-aos-offset="200"
          className="hidden md:block col-span-3 bg-white rounded-xl border-[1px] border-border p-6"
        >
          <div
            data-aos="fade-right"
            data-aos-duration="1000"
            data-aos-delay="100"
            data-aos-offset="200"
            className="col-span-12 flex-colo gap-6 lg:col-span-4 bg-white lg:sticky top-28"
          >
            <img
              src={
                patient?.data
                  ? patient.data?.persona?.perfil
                  : "https://png.pngtree.com/png-vector/20230131/ourmid/pngtree-flat-user-profile-icon-on-isolated-background-with-permission-sign-vector-png-image_49602760.jpg"
              }
              alt="setting"
              className="w-20 h-20 rounded-full object-cover border border-dashed border-subMain"
            />
            <div className="gap-2 flex-colo">
              <h2 className="text-sm font-semibold">{`${patient.data?.persona?.nombre} ${patient.data?.persona?.apellidos}`}</h2>
              <a
                href={`mailto:${patient.data?.persona?.email}`}
                className="text-xs text-textGray"
              >
                {patient.data?.persona?.email}
              </a>
              <a
                href={`https://wa.me/+571${patient.data?.persona?.telf1}/`}
                target="_blank"
                rel="noreferrer"
                className="text-xs"
              >
                {patient.data?.persona?.telf1}
              </a>
              <p className="text-xs text-subMain bg-text font-medium py-1 px-4 rounded-full border-[0.5px] border-subMain">
                {getAge(new Date(patient.data?.persona?.fecha_nacimiento))} años{" "}
              </p>
            </div>
          </div>
        </div>
        <div
          data-aos="fade-left"
          data-aos-duration="1000"
          data-aos-delay="100"
          data-aos-offset="200"
          className="col-span-12 md:col-span-9 bg-white rounded-xl border-[1px] border-border p-6"
        >
          <MedicalRecord />
        </div>
      </div>
    </LayoutPatient>
  );
}

export default PatientHistory;
