import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiUrl } from "../apiUrl";

export const getPatients = createAsyncThunk("patient/getPatients", async () => {
  const { data } = await axios.get(`${apiUrl}/api/v1/patient`);
  return data;
});

export const updatePatient = createAsyncThunk(
  "patient/updatePatient",
  async (datos) => {
    const { id, archivo } = datos;

    const fd = new FormData();
    if (archivo) {
      fd.append("archivo", archivo, archivo.name);
    }
    for (const key in datos) {
      const value = datos[key];
      if (value !== undefined && value !== null) {
        fd.append(key, value);
      }
    }
    const { data } = await axios.post(`${apiUrl}/api/v1/patient/${id}`, fd);

    return data;
  }
);

export const createPatient = createAsyncThunk(
  "patient/createPatient",
  async (datos, { rejectWithValue }) => {
    const { archivo } = datos;

    const fd = new FormData();
    if (archivo) {
      fd.append("archivo", archivo, archivo.name);
    }
    for (const key in datos) {
      const value = datos[key];
      if (value !== undefined && value !== null) {
        fd.append(key, value);
      }
    }
    try {
      const { data } = await axios.post(`${apiUrl}/api/v1/patient`, fd);
      return data;
    } catch (error) {
      // Return the error response from the backend
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  status: "idle",
  listStatus: "idle",
  edit: false,
  patient: {},
  patients: [],
  error: null,
};

export const patientSlice = createSlice({
  name: "patient",
  initialState,
  reducers: {
    setPatient: (state, action) => {
      if (state.patients.data.length > 0) {
        state.patient = state.patients.data.find(
          (pat) => pat.id === action.payload
        );
      } else {
        state.patient = {};
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getPatients.pending, (state, action) => {
        state.listStatus = "loading";
      })
      .addCase(getPatients.fulfilled, (state, action) => {
        state.listStatus = "succeed";
        state.patients = action.payload;
      })
      .addCase(getPatients.rejected, (state, action) => {
        state.listStatus = "failed";
        state.error = action.error.message;
      })
      .addCase(updatePatient.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(updatePatient.fulfilled, (state, action) => {
        state.status = "succeed";
        state.patient = action.payload;
      })
      .addCase(updatePatient.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(createPatient.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(createPatient.fulfilled, (state, action) => {
        state.status = "succeed";
        state.patient = action.payload;
      })
      .addCase(createPatient.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { setPatient } = patientSlice.actions;

export default patientSlice.reducer;

export const getAllPatients = (state) => state.patient.patients;
export const getAllPatientsStatus = (state) => state.patient.listStatus;
export const getPatientStatus = (state) => state.patient.status;
export const getPatientError = (state) => state.patient.error;
export const getPatient = (state) => state.patient?.patient;
export const getPatientEdit = (state) => state.patient.edit;
