import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiUrl } from "../apiUrl";

export const getConfiguration = createAsyncThunk(
  "configuration/getConfiguration",
  async () => {
    const { data } = await axios.get(`${apiUrl}/api/v1/configuracion`);
    return data;
  }
);

export const getStatistics = createAsyncThunk(
  "configuration/getStatistics",
  async () => {
    const { data } = await axios.get(`${apiUrl}/api/v1/dashboard`);
    return data;
  }
);

export const updateConfiguration = createAsyncThunk(
  "configuration/updateConfiguration",
  async (body) => {
    const { data } = await axios.post(`${apiUrl}/api/v1/configuracion`, body);
    return data;
  }
);

const initialState = {
  status: "idle",
  configuration: "idle",
  statistics: "idle",
  error: null,
};

export const configurationSlice = createSlice({
  name: "configuration",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getConfiguration.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getConfiguration.fulfilled, (state, action) => {
        state.status = "succeed";
        state.configuration = action.payload;
      })
      .addCase(getConfiguration.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(getStatistics.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getStatistics.fulfilled, (state, action) => {
        state.status = "succeed";
        state.statistics = action.payload;
      })
      .addCase(getStatistics.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateConfiguration.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(updateConfiguration.fulfilled, (state, action) => {
        state.status = "succeed";
        state.configuration = action.payload;
      })
      .addCase(updateConfiguration.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default configurationSlice.reducer;

export const getAllConfiguration = (state) => state.configuration.configuration;
export const getAllStatistics = (state) => state.configuration.statistics;
export const getConfigurationStatus = (state) => state.configuration.status;
export const getConfigurationError = (state) => state.configuration.error;
