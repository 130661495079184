import React, { useEffect, useState } from "react";
import Modal from "./Modal";
import {
  Button,
  Checkbox,
  DatePickerComp,
  Input,
  Select,
  Textarea,
  TimePickerComp,
} from "../Form";
import { BiChevronDown } from "react-icons/bi";
import { memberData, servicesData } from "../Datas";
import { HiOutlineCheckCircle } from "react-icons/hi";
import { toast } from "react-hot-toast";
import { BsFileEarmarkPerson } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  createCita,
  getCitas,
  updateCita,
} from "../../redux/features/citasSlice";
import SearchBar from "../SearchBar";
import { useForm } from "react-hook-form";
import ConfirmationAlert from "../ConfirmationAlert";
import NewMedicalRecode from "../../screens/Patients/NewMedicalRecode";
import { FaNotesMedical } from "react-icons/fa";
import NewPatientFast from "./NewPatientFast";
import { getPatients } from "../../redux/features/patientSlice";
import { getVisitasByCitaId, resetVisita } from "../../redux/features/visitasSlice";

// edit member data
const doctorsData = memberData.map((item) => {
  return {
    id: item.id,
    name: item.title,
  };
});

function AddAppointmentModal({
  closeModal,
  isOpen,
  datas,
  operation,
  patients,
}) {
  const [services, setServices] = useState(servicesData[0]);
  const [atencion, setAtencion] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(
    new Date(new Date().getTime() + 30 * 60000)
  );
  const [openPatient, setOpenPatient] = useState(false);
  const [doctors, setDoctors] = useState(doctorsData[0]);
  const [selected, setSelected] = useState(null);
  const [shares, setShares] = useState({
    email: false,
    sms: false,
    whatsapp: false,
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({});

  const onSubmit = async (data) => {
    if (!selected?.id && !datas.id) {
      toast.error("Seleccione al Paciente Primero!");
      return;
    }
    if (datas.id) {
      data.patient_id = datas.patient_id;
    } else {
      if (selected?.id) data.patient_id = selected.id;
      else toast.error("Seleccione al Paciente!!!", { duration: 5000 });
    }
    //const fechaAux = new Date(startDate.setHours(4));
    data.fecha = startDate.toISOString();
    let auxHoraInicio = new Date(startDate.setHours(startTime.getHours()));
    data.hora_inicio = new Date(
      auxHoraInicio.setMinutes(startTime.getMinutes())
    ).toISOString();
    let auxHoraFin = new Date(startDate.setHours(endTime.getHours()));
    data.hora_fin = new Date(
      auxHoraFin.setMinutes(endTime.getMinutes())
    ).toISOString();
    data.tipo_evento = services.name;

    ConfirmationAlert({
      title: `${datas?.id ? "Actualizar" : "Registrar"} Cita`,
      text: `Esta seguro de ${datas?.id ? "actualizar" : "registrar"} la cita`,
      onAccept: async () => {
        if (datas?.id) {
          data.id = datas.id;
          const res = await dispatch(updateCita(data));
          if (res.error) {
            toast.error(
              "Ocurrio un error al actualizar la cita: " + res.error,
              { duration: 5000 }
            );
          } else {
            dispatch(getCitas());
            dispatch(getPatients());
            toast.success("Cita actualizada exitosamente!", { duration: 5000 });
            navigate(0);
          }
        } else {
          const res = await dispatch(createCita(data));
          if (res.error) {
            toast.error("Ocurrio un error al crear la cita: " + res.error, {
              duration: 5000,
            });
          } else {
            dispatch(getCitas());
            dispatch(getPatients());
            toast.success("Cita creada exitosamente!", { duration: 5000 });
            closeModal();
          }
        }
      },
    });
  };

  const handleAtencion = () => {
    setAtencion(true);
    dispatch(resetVisita());
  }
  // set data
  useEffect(() => {
    if (datas?.id) {
      setServices(
        servicesData.find((service) => service.name === datas?.tipo_evento)
      );
      setStartTime(datas?.start);
      setEndTime(datas?.end);
      setStartDate(new Date(datas.fecha));
      setValue("motivo", datas?.motivo);
    }
  }, [datas?.id]);

  return (
    <>
      {atencion ? (
        ConfirmationAlert({
          title: "Inicio de Atencion",
          text: `Esta seguro de iniciar la atencion?`,
          onAccept: async () => {
            const visitaResponse = await dispatch(getVisitasByCitaId(datas?.id));
            if (visitaResponse?.error) {
              toast.error(
                `No se pudo iniciar la atencion: ${visitaResponse.error}`,
                { duration: 5000 }
              );
            } else {
              toast.success("Datos de visita guardados con exito!", {
                duration: 5000,
              });
              setTimeout(() => navigate(`/patients/preview/${datas.patient.id}`), 500);
            }
          },
        })
      ) : (
        <Modal  
          closeModal={closeModal}
          isOpen={isOpen}
          title={datas?.title ? "Editar Cita" : "Nueva Cita"}
          width={"max-w-3xl"}
        >
          <div className="flex-colo gap-6">
            <div className="grid sm:grid-cols-12 gap-4 w-full items-center">
              {openPatient && (
                <NewPatientFast
                  open={openPatient}
                  setOpen={setOpenPatient}
                  setPatient={setSelected}
                />
              )}
              <div className="sm:col-span-10">
                {datas?.id ? (
                  <div className="flex flex-col sm:flex-row justify-between w-full gap-4">
                    <p> 
                      <span className=" font-semibold"> Paciente: </span>{" "}
                      {datas?.patient?.persona?.nombre}{" "}
                      {datas?.patient?.persona?.apellidos}
                    </p>
                    <p className="mt-2">
                      <span className=" font-semibold">Estado: </span>
                      {datas.estado}
                    </p>
                  </div>
                ) : (
                  <SearchBar
                    placeholder="Escriba nombre o CI para buscar al paciente"
                    data={patients}
                    setSelected={setSelected}
                    value={
                      selected
                        ? `${
                            selected.persona.ci.length > 10
                              ? selected.persona.ci.slice(0, 8)
                              : selected.persona.ci
                          } - ${selected.persona.nombre} ${
                            selected.persona.apellidos
                          }`
                        : ""
                    }
                    setOpen={setOpenPatient}
                  />
                )}
              </div>
            </div>

            <div className="grid sm:grid-cols-3 gap-4 w-full">
              <div className="flex w-full flex-col gap-3">
                <p className="text-black text-sm">Tipo de evento</p>
                <Select
                  selectedPerson={services}
                  setSelectedPerson={setServices}
                  datas={servicesData}
                >
                  <div className="w-full flex-btn text-textGray text-sm p-4 border border-border font-light rounded-lg focus:border focus:border-subMain">
                    {services?.name} <BiChevronDown className="text-xl" />
                  </div>
                </Select>
              </div>
              {/* date */}
              <DatePickerComp
                label="Fecha"
                startDate={startDate}
                onChange={(date) => setStartDate(date)}
              />
            </div>

            <div className="grid sm:grid-cols-2 gap-4 w-full">
              <TimePickerComp
                label="Hora Inicio"
                startDate={startTime}
                onChange={(date) => {
                  setStartTime(date);
                  setEndTime(new Date(date.getTime() + 30 * 60000));
                }}
              />
              <TimePickerComp
                label="Hora Fin"
                startDate={endTime}
                onChange={(date) => setEndTime(date)}
              />
            </div>
            {/* des */}
            <Textarea
              register={register("motivo", {
                required: "El motivo de la cita es requerido.",
              })}
              name="motivo"
              setValue={setValue}
              getValue={getValues}
              label="Motivo de la Cita"
              placeholder={
                datas?.message
                  ? datas.message
                  : "El paciente refiere malestar....."
              }
              color={true}
              rows={5}
              error={errors?.motivo}
            />

            {/* buttones */}
            <div
              className={"flex flex-col sm:flex-row justify-between w-full gap-4"}
            >
              {datas?.id && (
                datas?.estado === "cerrada" ? (
                  <Link
                    to={`/patients/preview/${datas.patient.id}`}
                    className="bg-subMain h-12 rounded-lg flex justify-center items-center text-white text-center w-full"
                  >
                    <BsFileEarmarkPerson />
                    Paciente
                  </Link>
                ) : (
                  <Button
                    label={`${datas?.tipo_evento === "Analisis o Laboratorio" ? "Abrir Analisis o Laboratorio" : "Inicar Atencion"}`}
                    onClick={handleAtencion}
                    Icon={FaNotesMedical}
                  />
                )
              )}
              <Button
                label={`${datas?.id ? "Actualizar" : "Agendar"} Cita`}
                Icon={HiOutlineCheckCircle}
                onClick={handleSubmit(onSubmit)}
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}

export default AddAppointmentModal;
